import React from 'react'

function EyeIcon() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.33398 7.99967C1.33398 7.99967 3.33398 3.33301 8.00065 3.33301C12.6673 3.33301 14.6673 7.99967 14.6673 7.99967C14.6673 7.99967 12.6673 12.6663 8.00065 12.6663C3.33398 12.6663 1.33398 7.99967 1.33398 7.99967Z"
        stroke="white"
        strokeWidth="1.16667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 10C9.10457 10 10 9.10457 10 8C10 6.89543 9.10457 6 8 6C6.89543 6 6 6.89543 6 8C6 9.10457 6.89543 10 8 10Z"
        stroke="white"
        strokeWidth="1.16667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
export default EyeIcon
