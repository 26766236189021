import React, { useReducer } from 'react'
import { DateRangeInput } from '@datepicker-react/styled'
import styled from 'styled-components'
import { formatDate } from 'lib/formatters'

const StyledDate = styled.div`
  position: relative;
  z-index: 1000;
  backface-visibility: hidden;
  transform: translate3d(0, 0, 0);
  margin-bottom: 10px;
  padding: 2px 0;
  height: 40px;
`
function reducer(state, action) {
  switch (action.type) {
    case 'focusChange':
      return { ...state, focusedInput: action.payload }
    case 'dateChange':
      return action.payload
    default:
      throw new Error()
  }
}

const initialState = {
  startDate: null,
  endDate: null,
  focusedInput: null,
}
export default function DateRangePicker({ onStateChange, props, initialDates: [start, end] = [] }) {
  const [state, dispatch] = useReducer(reducer, { ...initialState, startDate: start, endDate: end })

  function onChange({ startDate, endDate }) {
    if (startDate && endDate) {
      onStateChange && onStateChange([startDate, endDate].map(formatDate))
    }
  }
  return (
    <StyledDate {...props}>
      <DateRangeInput
        onDatesChange={(data) => {
          dispatch({ type: 'dateChange', payload: data })
          onChange(data)
        }}
        onFocusChange={(focusedInput) => dispatch({ type: 'focusChange', payload: focusedInput })}
        startDate={state.startDate} // Date or null
        endDate={state.endDate} // Date or null
        focusedInput={state.focusedInput} // START_DATE, END_DATE or null
        displayFormat={'dd/MM/yyyy'}
      />
    </StyledDate>
  )
}
