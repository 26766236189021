import client from 'lib/client'
import toQueryString from 'lib/queryString'

export const fetchCampaigns = (query) => client.get(`i/campaigns/GetCampaign`, query)
export const setupCampaign = (body) => client(`i/campaigns/SetupCampaign`, body)
export const fetchCampaign = (query) => client.get(`i/campaigns/RetrieveCampaignById`, query)

export const fetchAggregatorFieldStaff = () => client.get(`i/agents/RetrieveAggregatorsAndFieldStaffOnly`)
export const mapAggregatorToCampaign = (body) => client(`i/agents/MapAggregatorToCampaign`, body)
export const deleteCampaign = (query) => client.post(`i/campaigns/DeleteCampaign${toQueryString(query)}`)
