import client, { makeClient } from 'lib/client'
import { X_REQUEST_LOCK } from 'lib/config'
import makeFormData from 'lib/makeFormData'
import toQueryString from 'lib/queryString'
import { pickErrorMessage, pickResult } from 'lib/axios.client'
import { getToken } from 'agents/agents.api.client'

const passValue = (args) => args
const PROD_ALTERNATE_URL = process.env.REACT_APP_PROD_ALTERNATE_URL

// A separate client using a different base URL is created for BAS service on production to reduce the cost on spent backoffice.
//For other enviroments we defualt to the main client.

export const prodAlternateClient =
  process.env.NODE_ENV === 'production'
    ? makeClient(
        PROD_ALTERNATE_URL,
        {
          'X-REQUEST-LOCK': X_REQUEST_LOCK,
          'App-Id': 'xH1pQ3lVXNT0wsUqGJ/s/vGCAKuy9uNR',
        },
        () => ({
          Authorization: ['Bearer', getToken()].join(' '),
        }),
        {
          onSuccess: passValue,
          onError: Promise.reject,
        }
      )
    : client

//Function to conditionally apply pickResult and pickErrorMessage based on environment in order to
//handle the response on the prod enviroment when using the alternative prod URL
export const handleResponse = (promise) => {
  if (process.env.NODE_ENV === 'production') {
    return promise.then(pickResult, pickErrorMessage)
  }
  return promise
}

// API FOR  VIRTUAL ACCOUNT TRANSACTIONS

export const fetchTransactions = (query) =>
  handleResponse(
    prodAlternateClient.get('bas/transactions/instafin', query, {
      headers: { 'X-REQUEST-LOCK': X_REQUEST_LOCK },
    })
  )

export const getTransactionSearchByList = (query) =>
  handleResponse(
    prodAlternateClient.get('bas/transactions/GetSearchByList', query, {
      headers: { 'X-REQUEST-LOCK': X_REQUEST_LOCK },
    })
  )

export const getSearchTransactionStatusList = (query) =>
  handleResponse(
    prodAlternateClient.get('bas/transactions/GetSearchTransactionStatuslist', query, {
      headers: { 'X-REQUEST-LOCK': X_REQUEST_LOCK },
    })
  )

// API FOR  VIRTUAL ACCOUNT
export const fetchaccounts = (query) =>
  handleResponse(
    prodAlternateClient.get('bas/virtualaccount/allaccounts', query, { headers: { 'X-REQUEST-LOCK': X_REQUEST_LOCK } })
  )

export const getSearchAccountStatusList = (query) =>
  handleResponse(
    prodAlternateClient.get('bas/virtualaccount/GetSearchAccountStatuslist', query, {
      headers: { 'X-REQUEST-LOCK': X_REQUEST_LOCK },
    })
  )

export const getVirtualAccountSearchByList = (query) =>
  handleResponse(
    prodAlternateClient.get('bas/virtualaccount/GetSearchByList', query, {
      headers: { 'X-REQUEST-LOCK': X_REQUEST_LOCK },
    })
  )

export const closeAccount = (body) =>
  handleResponse(
    prodAlternateClient(`bas/virtualaccount/close`, body, { headers: { 'X-REQUEST-LOCK': X_REQUEST_LOCK } })
  )

export const getSearchAccountTypeList = (query) =>
  handleResponse(
    prodAlternateClient.get('bas/virtualaccount/GetSearchAccountTypeList', query, {
      headers: { 'X-REQUEST-LOCK': X_REQUEST_LOCK },
    })
  )

export const bulkRequeryVirtualAccountTransactions = (body) =>
  handleResponse(
    prodAlternateClient(`bas/transactions/BulkReprocessInstafinTransactions`, makeFormData(body), {
      headers: { 'X-REQUEST-LOCK': X_REQUEST_LOCK },
    })
  )

export const createAccount = (body) =>
  handleResponse(
    prodAlternateClient(`bas/virtualaccount/create`, body, { headers: { 'X-REQUEST-LOCK': X_REQUEST_LOCK } })
  )

export const getAccountStatus = (query) =>
  handleResponse(
    prodAlternateClient.get(
      `bas/virtualaccount/accountStatus/${query}`,
      {},
      { headers: { 'X-REQUEST-LOCK': X_REQUEST_LOCK } }
    )
  )

export const activateAccount = (query) =>
  handleResponse(
    prodAlternateClient.patch(
      `bas/virtualaccount/ActivateDormantAccount${toQueryString(query)}`,
      {},
      {
        headers: { 'X-REQUEST-LOCK': X_REQUEST_LOCK },
      }
    )
  )
