import React from 'react'
import { InputLoader } from 'design/loaders'
import Box from 'design/elements/Box'
import withTitle from 'lib/withTitle'
import Button from 'design/elements/Button'
import Panel from 'design/elements/Panel'
import Text from 'design/elements/Text'
import AgentKycDocument from 'kyc/Tables/AgentKycDocumentsTable'
import Modal from 'design/elements/Modal'
import KycDocumentHistoryModal from 'kyc/screens/KycDocumentHistoryModal'
import IconBtn from 'design/elements/IconBtn'
import Close from 'design/assets/images/close.svg'
import { useAgentKycDocumentData } from 'kyc/Hooks/useAgentKycDocumentData'

export default withTitle('Agent KYC document list', KycDocumentList)
function KycDocumentList({ loading }) {
  const {
    history,
    agentInformation,
    agentDocumentsDetails,
    isFetching,
    documentHistoryModal,
    setDocumentHistoryModal,
    documentType,
    agentId,
    agentDocumentId,
    showModal,
    setAgentId,
    setAgentDocumentId,
    setDocumentType,
  } = useAgentKycDocumentData()

  const kycLevel = {
    LevelZero: 'KYC level 0',
    LevelOne: 'KYC level 1',
    LevelTwo: 'KYC level 2',
    LevelThree: 'KYC level 3',
    LevelFour: 'KYC level 4',
  }

  return (
    <Box>
      {loading ? (
        <Box height="80vh" display="flex" justifyContent="center" alignItems="center">
          <InputLoader size={7} />
        </Box>
      ) : (
        <>
          {documentHistoryModal && (
            <Modal close={setDocumentHistoryModal}>
              <Box width="700px" fontSize="md">
                <Panel px="xl" py="md" display="flex" justifyContent="space-between" alignItems="center">
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    gridGap="0.5em"
                  >
                    <Text fontWeight={500} fontSize={12} color="#8095A7">
                      Agent Name
                    </Text>
                    <Text fontWeight={600} fontSize={17} color="#1C3244">
                      {agentInformation?.full_name || '-'}{' '}
                    </Text>
                  </Box>
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    gridGap="0.5em"
                  >
                    <Text fontWeight={500} fontSize={12} color="#8095A7">
                      Agent Username
                    </Text>
                    <Text fontWeight={600} fontSize={17} color="#1C3244">
                      {agentInformation?.username || '-'}{' '}
                    </Text>
                  </Box>
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    gridGap="0.5em"
                  >
                    <Text fontWeight={500} fontSize={12} color="#8095A7">
                      Document Type
                    </Text>
                    <Text fontWeight={600} fontSize={17} color="#1C3244">
                      {documentType}
                    </Text>
                  </Box>

                  <IconBtn onClick={setDocumentHistoryModal} color="#8b9ba8">
                    <img src={Close} alt="close" />
                  </IconBtn>
                </Panel>
                <KycDocumentHistoryModal agentId={agentId} agentDocumentId={agentDocumentId} />
              </Box>
            </Modal>
          )}
          <Button onClick={() => history.goBack()} small variant="default">
            &larr; Back
          </Button>
          <Panel display="flex" px="md" py="md" gridGap="3em" alignItems="center">
            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" gridGap="0.5em">
              <Text fontWeight={500} fontSize={12} color="#8095A7">
                AGENT DETAIL
              </Text>
              <Text fontWeight={600} fontSize={17} color="#1C3244">
                {agentInformation?.full_name || '-'}
              </Text>
            </Box>
            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" gridGap="0.5em">
              <Text fontWeight={500} fontSize={12} color="#8095A7" mb="5px">
                EMAIL ADDRESS
              </Text>
              <Text fontWeight={600} fontSize={17} color="#1C3244">
                {agentInformation?.email || '-'}
              </Text>
            </Box>
            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" gridGap="0.5em">
              <Text fontWeight={500} fontSize={12} color="#8095A7" mb="5px">
                PHONE NUMBER
              </Text>
              <Text fontWeight={600} fontSize={17} color="#1C3244">
                {agentInformation?.phone || '-'}
              </Text>
            </Box>
            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
              <Text fontWeight={500} fontSize={12} color="#8095A7" mb="5px">
                KYC LEVEL
              </Text>
              <Box
                borderRadius="40px"
                border="1px solid #37A477"
                background="#EBF6F1"
                display="flex"
                padding="4px 12px"
                justifyContent="center"
                fontWeight={500}
                fontSize={15}
                color="#37A477"
              >
                {kycLevel[agentInformation?.kyc_level] || '-'}
              </Box>
            </Box>
          </Panel>
          <AgentKycDocument
            loading={isFetching}
            Documents={agentDocumentsDetails}
            showModal={showModal}
            setAgentId={setAgentId}
            setAgentDocumentId={setAgentDocumentId}
            setDocumentType={setDocumentType}
          />
        </>
      )}
    </Box>
  )
}
