import React from 'react'
import { Label } from './TextField'
import Chevron from 'design/assets/images/chevron.svg'
import Spacer from './Spacer'
import Box from './Box'
import styled from 'styled-components'
import Downshift from 'downshift'
import { InputLoader } from 'design/loaders'

const StyledSelect = styled(Box)`
  position: relative;
  .chevron{
    position: absolute;
    top:${(props) => (props.small ? '20%' : '30%')};
    right: 1em;
  }
   .dropdownButton{
    position: relative;
    background: white;
    padding: 0 1em;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    z-index: inherit;
    font-family: inherit;
    font-size:${(props) => (props.small ? '10px' : '12px')};
    color:  ${(props) => props.theme.colors.text};
    height:${(props) => (props.small ? '25px' : '40px')};
    border: solid 1px   ${(props) => (props.error ? props.theme.colors.red : '#dbe0e5')};
    transition: inherit;
    :disabled{
      opacity: 0.3;
    }
    input{
      width: 80%;
      height: 100%;
      border: none;
      outline: none;
      padding-right: 0.5em;
      color:#1c3244f2;
      font-family: inherit;
      font-size: ${(props) => (props.small ? '10px' : '12px')};
      ::placeholder {
        color: #8b9ba8;
        font-family: inherit;
      }
    }
  }  
  
  .dropdownMenu{
    position: absolute;
    z-index: 100;
    max-width: 100%;
    max-height: 300px;
    top:calc(100% + 2px);
    left:0;
    right:0;
    opacity: 1;
    background: white;
    list-style: none;
    box-shadow: 0 9px 13px 0 rgba(0,0,0,0.05);
    overflow-x: hidden;
    overflow-y: auto;
  :focus{
      outline: 1px solid currentColor;
  }
  }
  .dropdownItem{
    position: relative;
    z-index: inherit;
    cursor: pointer;
    font-size: 12px;
    margin: .5em 0;
    padding: .5em 0.5em;
    transition: all 0.1s linear;
    text-align: left;
    outline: none;
    box-shadow:none;
  }
  .dropdownItem.highlighted{
    color: white;
    background:${(props) => props.theme.colors.highlight}
  }
  .dropdownItem.selected{
    color: ${(props) => props.theme.colors.highlight};
    background:${(props) => props.theme.colors.lightgrey}
  } 
 .message {
    font-size: x-small;
    display: block;
    height: 20px;
    padding: 0.5em 0;
    transition: all 0.2s linear;
    position: absolute;
    color: ${(props) => (props.error ? props.theme.colors.red : props.theme.colors.default)};
  }
  }`

function Select(props, ref) {
  const {
    loading,
    options = [],
    label = 'Select an option...',
    labelField = 'label',
    valueField = 'value',
    disabled,
    helperText,
    name,
    value,
    placeholder,
    onChange,
    onBlur,
    error,
    small,
    ...styleProps
  } = props

  const itemToString = (item) => item?.[labelField]

  function _onChange(...args) {
    onChange && onChange(...args)
  }
  const _value = React.useMemo(() => options.find((opt) => opt?.[valueField] === value), [value, options, valueField])

  return (
    <Downshift selectedItem={_value} onChange={_onChange} itemToString={itemToString}>
      {({
        getInputProps,
        getItemProps,
        getLabelProps,
        getMenuProps,
        getRootProps,
        getToggleButtonProps,
        isOpen,
        inputValue,
        highlightedIndex,
        selectedItem,
      }) => (
        <StyledSelect error={error} small={small} forwardedAs="div" isOpen={isOpen} {...styleProps} {...getRootProps()}>
          <Label small={small} {...getLabelProps()}>
            {label}
          </Label>
          <Spacer mt="xs" />
          <button disabled={disabled} className="dropdownButton" type="button" {...getToggleButtonProps()}>
            <input name={name} type="text" {...getInputProps({ ref })} onBlur={onBlur} placeholder={placeholder} />
            {loading ? (
              <Box as="div" ml="auto" mr="5px" children={<InputLoader />} />
            ) : (
              <img src={Chevron} alt="" className="chevron" width={15} />
            )}
          </button>
          <ul className="dropdownMenu" {...getMenuProps()}>
            {isOpen &&
              options
                .filter((item) => !inputValue || item?.[labelField]?.toLowerCase().includes(inputValue?.toLowerCase()))
                .map((option, index) => {
                  return (
                    <li
                      className={[
                        index === highlightedIndex ? 'highlighted' : undefined,
                        'dropdownItem',
                        selectedItem?.[labelField] === option[labelField] && 'selected',
                      ]
                        .filter(Boolean)
                        .join(' ')}
                      {...getItemProps({ item: option, index })}
                      key={`${option}${index}`}
                    >
                      {option[labelField]}
                    </li>
                  )
                })}
          </ul>
          {helperText && <span className="message">{helperText}</span>}
        </StyledSelect>
      )}
    </Downshift>
  )
}

export default React.forwardRef(Select)
