import React from 'react'
import { useToast } from 'toast/ToastContext'
import useQueryParams from 'lib/hooks/useQueryParams'
import { useQuery, useMutation, queryCache } from 'react-query'
import {
  fetchCampaigns,
  fetchAggregatorFieldStaff,
  mapAggregatorToCampaign,
  deleteCampaign,
} from 'campaigns/campaigns.api.client'
import { useForm } from 'react-hook-form'
import { toNativeDate } from 'lib/formatters'

export function useCampaignList() {
  const { notify, alert } = useToast()
  const [selectedAggregators, setSelectedAggregators] = React.useState([])
  const [loadCause, setLoadCause] = React.useState('INITIAL')
  const [search, setSearch] = React.useState('')

  const queryParams = useQueryParams()
  const searchString = React.useRef(queryParams.searchString)
  const [query, setQuery] = React.useState({ ...queryParams, pageIndex: 1, pageSize: 10 })
  const formRef = React.useRef({
    campaign_id: queryParams.campaign_id,
  })

  const { data: campaigns, status } = useQuery([`all-campaigns`, query], (_, _query) => fetchCampaigns(_query), {
    staleTime: 60 * 60 * 1000,
    onSettled() {
      setLoadCause(null)
    },
  })

  const handlePageChange = (newPage) => {
    setQuery((q) => ({ ...q, pageIndex: newPage }))
  }

  const handleSearch = React.useCallback((e) => {
    e.preventDefault()
    let value = e.target.search.value
    if (!value && !searchString.current) {
      return
    }
    searchString.current = value
    setLoadCause('SEARCH')
    setQuery((q) => ({ ...q, searchString: searchString.current, pageIndex: 1 }))
  }, [])

  const { data: _aggregatorFieldStaff = [] } = useQuery('aggregator-field-staff', fetchAggregatorFieldStaff, {
    staleTime: Infinity,
  })

  const handleMapToCampaign = React.useCallback(
    (e) => {
      e.persist()
      e.preventDefault()
      const form = {
        agent_id: selectedAggregators[0]?.value,
        campaign_id: formRef.current.campaign_id,
      }
      mapAggregatorToCampaign(form)
        .then((res) => {
          notify(res)
        })
        .catch((err) => {
          alert(err)
        })
    },
    [alert, notify, selectedAggregators]
  )

  const aggregatorsFieldStaff = React.useMemo(
    () =>
      _aggregatorFieldStaff?.map((item) => ({
        label: item.username,
        value: item.agent_id,
      })),
    [_aggregatorFieldStaff]
  )

  const { data: _campaignGroupOptions, isFetching } = useQuery({
    queryKey: ['search-names', { search }],
    variables: [search],
    queryFn: async () => {
      let res
      if (search?.length > 0) {
        res = await fetchCampaigns({ pageIndex: 1, pageSize: 10, searchString: search })
        return res
      }
      return res
    },
  })
  const campaignGroupOptions = React.useMemo(
    () =>
      _campaignGroupOptions?.data?.map((item) => ({
        name: item.title,
        value: item.id,
      })),
    [_campaignGroupOptions?.data]
  )

  return {
    aggregatorsFieldStaff,
    setSelectedAggregators,
    formRef,
    campaigns,
    status,
    handleMapToCampaign,
    loadCause,
    handlePageChange,
    handleSearch,
    searchString,
    setSearch,
    campaignGroupOptions,
    isFetching,
  }
}

export function useCampaignTable() {
  const { notify, alert, clearAlert } = useToast()
  const deleteCampaigns = React.useCallback(
    (cb, context) => {
      clearAlert()
      cb && cb()
      const payload = { id: context.id }
      deleteCampaign(payload)
        .then((res) => {
          notify(res)
        })
        .catch((err) => {
          alert(err)
        })
    },
    [alert, clearAlert, notify]
  )
  return { deleteCampaigns }
}

export function useCampaignForm(campaign, submitFn) {
  const firstMount = React.useRef(true)
  const { notify, alert, clearAlert } = useToast()
  const dateRange = React.useRef(!!campaign ? [campaign.start_date, campaign.end_date] : [null, null])

  const form = useForm({
    mode: 'onBlur',
    defaultValues: Object.assign({}, { start_date: dateRange.current[0], end_date: dateRange.current[1] }, campaign),
  })

  React.useEffect(() => {
    if (!firstMount.current) return
    form.register({ name: 'campaign_status', type: 'custom' }, { required: true })
    form.register({ name: 'pricing_category_id', type: 'custom' }, { required: true })
    form.register({ name: 'start_date', type: 'custom' }, { required: false })
    form.register({ name: 'end_date', type: 'custom' }, { required: false })

    return () => {
      firstMount.current = false
    }
  }, [form])

  const onDateChange = React.useCallback(
    ([start, end]) => {
      dateRange.current = [start, end]
      form.setValue('start_date', toNativeDate(start).toISOString())
      form.setValue('end_date', toNativeDate(end).toISOString())
    },
    [form]
  )

  const [mutate, { status }] = useMutation(submitFn, {
    onSuccess() {
      queryCache.removeQueries(`all-campaigns`)
      let message = `The campaign was successfully ${!!campaign ? 'updated' : 'saved'}`
      notify(message)
    },
    onError(err) {
      alert(typeof err === 'string' && err)
    },
  })

  const onSubmit = React.useCallback(
    (values) => {
      clearAlert()
      mutate(values)
    },
    [clearAlert, mutate]
  )
  return { form, onSubmit, dateRange, onDateChange, status }
}
