import * as React from 'react'
import styled from 'styled-components'

/**
 * ? Local Import
 */
import Box from 'design/elements/Box'

export const BankSelect = styled.select`
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
  text-align: center;
  border: 1px solid #dddddd;
  border-radius: 6px;
  padding: 6px 10px;
  background: #ffffff;
  cursor: pointer;
  width: 50px;
  font-family: 'Avenir', serif;
  font-weight: 600;
`

export const TimeSelector = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '10px',
  background: '#EEEEEE',
  borderRadius: '6px',
  marginBottom: '25px',
})

export const TimeColumn = styled.span`
  margin: 0 5px;
  fontsize: 9px;
  fontweight: bold;
  color: black;
`

export default function CustomSelect({ name, onChange, value, options = [], ...props }) {
  return (
    <BankSelect name={name} value={value} onChange={onChange} {...props}>
      {options.map((option, idx) => {
        const padValue = option.toString().padStart(2, '0')
        return (
          <option key={option.toString() + idx.toString()} value={padValue}>
            {padValue}
          </option>
        )
      })}
    </BankSelect>
  )
}
