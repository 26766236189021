import React from 'react'
import withTitle from 'lib/withTitle'
import Box from 'design/elements/Box'
import { InputLoader } from 'design/loaders'
import KycDocumentHistoryTable from 'kyc/Tables/KycDocumentHistoryTable'
import { fetchAgentKycDocumentHistory } from 'kyc/Kyc.api.client'
import { useQuery } from 'react-query'


export default withTitle('Agent KYC document history', KycDocumentHistory)
function KycDocumentHistory({ loading, agentId, agentDocumentId }) {
    
  const {
    data: agentDocumentsDetails,
    status,
    isFetching,
  } = useQuery(['agent_documents_history_details', agentId, agentDocumentId], () => fetchAgentKycDocumentHistory(agentId, agentDocumentId), {
    staleTime: 5 * 60 * 1000,
    notifyOnStatusChange: false,
  })

  return (
    <Box>
      {loading ? (
        <Box height="80vh" display="flex" justifyContent="center" alignItems="center">
          <InputLoader size={7} />
        </Box>
      ) : (
        <>
          <KycDocumentHistoryTable loading={isFetching} Documents={agentDocumentsDetails} />
        </>
      )}
    </Box>
  )
}
