import styled from 'styled-components'
const Menu = styled.ul`
  background: white;
  list-style: none;
  width: 150px;
  overflow-x: hidden;
  box-shadow: 0 10px 30px 0 #00000029;
  border: solid 1px #e3ecf4;
  transition: all 0.2s linear;
`
export default Menu
