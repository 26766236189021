import React from 'react'
import Button from 'design/elements/Button'
import AdditionalContactDetailsModal from 'agents/modals/AdditionalContactDetailsModal'
import Box from 'design/elements/Box'
import IconBtn from 'design/elements/IconBtn'
import Modal from 'design/elements/Modal'
import Panel from 'design/elements/Panel'
import Close from 'design/assets/images/close.svg'
import { useToggle } from 'lib/hooks/useToggle'

const AdditionalContactDetails = (AgentId) => {
  const [contactDetailsModal, setContactDetailsModal] = useToggle(false)

  const showModal = () => {
    setContactDetailsModal()
  }

  return (
    <>
      <Button variant="blue" fullWidth onClick={showModal}>
        Show Additional Contact Details
      </Button>
      {contactDetailsModal && (
        <Modal close={setContactDetailsModal}>
          <Box width="700px" fontSize="md">
            <Panel px="xl" py="md" display="flex" justifyContent="space-between" alignItems="center">
              <IconBtn onClick={setContactDetailsModal} color="#8b9ba8">
                <img src={Close} alt="close" />
              </IconBtn>
            </Panel>
            <AdditionalContactDetailsModal AgentId={AgentId} />
          </Box>
        </Modal>
      )}
    </>
  )
}

export default AdditionalContactDetails
