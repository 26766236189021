import React from 'react'
import { InputLoader } from 'design/loaders'
import Box from 'design/elements/Box'
import AgentForm from 'agents/forms/AgentForm'
import withTitle from 'lib/withTitle'
import Button from 'design/elements/Button'
import { useHistory } from 'react-router-dom'
import Panel from 'design/elements/Panel'
import Text from 'design/elements/Text'

export default withTitle('Update Agent', KycEditAgent)
function KycEditAgent({ loading, agent, notify, alert, clearAlert }) {
  const history = useHistory()
  return (
    <Box>
      {loading ? (
        <Box height="80vh" display="flex" justifyContent="center" alignItems="center">
          <InputLoader size={7} />
        </Box>
      ) : (
        <>
          <Button onClick={() => history.goBack()} small variant="default">
            &larr; Back
          </Button>
          <Panel display="flex" alignItems="center" px="md" py="md">
            <Text color="#1C3244" fontWeight="bold">
              Agents profile
            </Text>
          </Panel>
          <AgentForm
            isDataFromServer={!loading}
            agent={agent}
            notify={notify}
            alert={alert}
            clearAlert={clearAlert}
            imageLoading={loading}
          />
        </>
      )}
    </Box>
  )
}
