import styled from 'styled-components'
import React from 'react'

const Elippsis = styled.span`
  display: flex;
  flex-direction: ${(props) => (props.column ? 'column' : 'row')};
  justify-content: space-between;
  cursor: pointer;
  align-items: center;
  width: 15px;
  margin: 0 auto;
  padding: 5px 0;
  color: #707070;
  transition: all 0.2s linear;
  opacity: 0.5;
  &:hover {
    opacity: 1;
  }

  svg {
    fill: currentColor;
    display: inline-block;
    margin-right: ${(props) => (props.column ? '' : '0.1em')};
    margin-bottom: ${(props) => (props.column ? '0.1em' : '0')};
  }
`
function ElippsisIcon(props, ref) {
  return (
    <Elippsis {...props} ref={ref}>
      <svg width={5} viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
        <circle cx="50" cy="50" r="50" />
      </svg>
      <svg width={5} viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
        <circle cx="50" cy="50" r="50" />
      </svg>
      <svg width={5} viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
        <circle cx="50" cy="50" r="50" />
      </svg>
    </Elippsis>
  )
}
export default React.forwardRef(ElippsisIcon)
