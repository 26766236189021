import * as React from 'react'
import { useForm } from 'react-hook-form'

/**
 * ? Local Imports
 */
import { useUpdateAgentNOfKinDetails } from 'agents/hooks/useUpdateAgent'

import Box from 'design/elements/Box'
import Button from 'design/elements/Button'
import Divider from 'design/elements/Divider'
import Spacer from 'design/elements/Spacer'
import Text from 'design/elements/Text'
import TextField from 'design/elements/TextField'

import { loading } from 'lib/formatters'
import { ShowTree } from 'lib/gate'
import { roles } from 'lib/models'

const NextOfKinForm = ({ agent, notify, alert, clearAlert }) => {
  const form = useForm({
    mode: 'onBlur',
  })

  const [updateNOfKinDetails, { status }] = useUpdateAgentNOfKinDetails({ notify, alert, clearAlert })

  const updateForm = React.useCallback(
    (_formValues) => {
      const agentId = agent?.agent_id
      const _formBody = Object.assign({}, _formValues, {
        agent_id: +agentId,
      })
      updateNOfKinDetails(_formBody)
    },
    [agent, updateNOfKinDetails]
  )

  return (
    <Box as="form" onSubmit={form.handleSubmit(updateForm)}>
      <Text textTransform="uppercase" fontWeight="bold">
        Next of Kin
      </Text>
      <Divider />
      <TextField
        label="Name"
        name="next_of_kin"
        error={form.errors?.next_of_kin}
        helperText={form.errors?.next_of_kin && 'Please enter a valid name'}
        ref={form.register({ required: false, minLength: 3 })}
        defaultValue={agent?.next_of_kin}
      />
      <Spacer mt="lg" />
      <TextField
        label="Next of Kin Email"
        name="next_of_kin_email"
        error={form.errors?.next_of_kin_email}
        helperText={form.errors?.next_of_kin_email && 'Please enter a valid name'}
        ref={form.register({ required: false, minLength: 3 })}
        defaultValue={agent?.next_of_kin_email}
      />
      <Spacer mt="lg" />
      <TextField
        name="next_of_kin_phone"
        error={form.errors?.next_of_kin_phone}
        helperText={form.errors?.next_of_kin_phone && 'Please enter a valid phone number'}
        ref={form.register({ required: false, minLength: 11, maxLength: 20 })}
        label="Phone Number"
        inputProps={{
          minLength: 11,
          maxLength: 20,
        }}
        type="tel"
        defaultValue={agent?.next_of_kin_phone}
      />
      <Spacer mt="lg" />
      <TextField
        label="Next of Kin Relationship"
        name="next_of_kin_relationship"
        error={form.errors?.relationship}
        helperText={form.errors?.next_of_kin_relationship && 'Please enter a valid name'}
        ref={form.register({ required: false, minLength: 3 })}
        defaultValue={agent?.next_of_kin_relationship}
      />
      <Spacer mt="lg" />
      <TextField
        label="Next of Kin Address"
        name="next_of_kin_address"
        error={form.errors?.next_of_kin_address}
        helperText={form.errors?.next_of_kin_address && 'Please enter a valid name'}
        ref={form.register({ required: false, minLength: 3 })}
        defaultValue={agent?.next_of_kin_address}
      />

      <ShowTree forRoles={[roles.OPPERATION_HEAD]}>
        <Button mt="lg" loading={loading(status)} type="submit" variant="success" fullWidth>
          Save
        </Button>
      </ShowTree>
    </Box>
  )
}

export default NextOfKinForm
