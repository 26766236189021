import { color, layout, space } from 'styled-system'
import styled from 'styled-components'

const IconBtn = styled.button`
  padding: 0;
  margin: 0;
  display: inline-flex;
  justify-content: center;
  cursor: pointer;
  align-items: center;
  ${color};
  ${space};
  ${layout};
`
export default IconBtn
