import React from 'react'

const _defaultState = {
  showPosVerificationActions: false,
  showPOSVerificationDocs: false,
  showAggregatorProofOfEvidenceActions: false,
  showAggregatorProofOfEvidenceDoc: false,
  showCACVerificationActions: false,
  showCACDoc: false,
  showCACStatusReportActions: false,
  showCACStatusReportDoc: false,
  showCacCertifiedApplicationActions: false,
  showCacCertifiedApplicationDoc: false,
  showAllotmentOfSharesActions: false,
  showAllotmentOfSharesDoc: false,
  showParticularsOfDirectorsActions: false,
  showParticularsOfDirectorsDoc: false,
  showMermatActions: false,
  showMermatDoc: false,
  showIncorporatedTrusteesFormActions: false,
  showIncorporatedTrusteesForm: false,
  showConstitutionActions: false,
  showConstitutionDoc: false,
  showBoardResolutionActions: false,
  showBoardResolution: false,
  showEvidenceofAddressActions: false,
  showEvidenceofAddressDoc: false,
  showIdentityCardActions: false,
  showIdentityCardDoc: false,
  showTransactionValueProofDoc: false,
  showTransactionValueProofActions: false,
  showPassportActions: false,
  showPassportDoc:false
}

function stateReducerMachine(state, action) {
  switch (action.type) {
    case 'TOGGLE_VISIBILITY':
      return { ...state, [action.payload]: !state[action.payload] }
    default:
      return state
  }
}

export default function useViewKYCDocumentsActions(callerDefaultState) {
  const defaultState = Object.assign({}, _defaultState, callerDefaultState)

  const [state, dispatch] = React.useReducer(stateReducerMachine, defaultState)

  const applyToggle = React.useCallback(
    (item) => {
      dispatch({
        type: 'TOGGLE_VISIBILITY',
        payload: item,
      })
    },
    [dispatch]
  )

  return [
    state,
    {
      applyToggle,
    },
  ]
}
