import React from 'react'
import FullTable from 'design/elements/FullTable'
import TR from 'design/elements/TR'
import TD from 'design/elements/TD'
import Elippsis from 'design/icons/Elippsis'
import MenuItem from 'design/elements/MenuItem'
import TH from 'design/elements/TH'
import Text from 'design/elements/Text'
import Menu from 'design/elements/Menu'

export default function AdditionalContactDetailsTable({ paginatedResponse, loading }) {
  const Agents = Array.isArray(paginatedResponse?.data) ? paginatedResponse?.data : undefined
  return (
    <FullTable
      elements={Agents}
      RenderHeaders={RenderHeaders}
      RenderRow={(props) => RenderRow({ ...props })}
      loading={loading}
      RenderNoData={<h3>There are no Additional contact Details provided</h3>}
      pagination={{
        perPage: paginatedResponse?.page_size,
        total: paginatedResponse?.count,
        currentPage: paginatedResponse?.page_index,
      }}
    />
  )
}

const RenderRow = ({ row: agent }) => {
  return (
    <TR>
      <TD>
        <Text fontWeight={500} textAlign="center">
          {agent.agent_contact_type === 0
            ? 'Phone number'
            : agent.agent_contact_type === 1
            ? 'Email Address'
            : agent.agent_contact_type === 2
            ? 'Address'
            : ''}
        </Text>
      </TD>
      <TD>
        <Text fontWeight={500} textAlign="center">
          {agent.agent_contact_info}
        </Text>
      </TD>
    </TR>
  )
}

const RenderHeaders = () => (
  <tr>
    <TH textAlign="center">Contact Type</TH>
    <TH textAlign="center">Contact Information</TH>
  </tr>
)
