import React from 'react'
import withTitle from 'lib/withTitle'
import Box from 'design/elements/Box'
import { InputLoader } from 'design/loaders'
import AdditionalContactDetailsTable from 'agents/tables/AdditionalContactDetailsTable'
import { getAgentsContact } from 'agents/agents.api.client'
import { useQuery } from 'react-query'

export default withTitle('Agent KYC document history', KycDocumentHistory)
function KycDocumentHistory({ loading, AgentId }) {
  const {
    data: agentsContact,
    isFetching,
  } = useQuery(['agent_contact_details', AgentId], () => getAgentsContact(AgentId), {
    staleTime: 5 * 60 * 1000,
    notifyOnStatusChange: false,
  })

  return (
    <Box>
      {loading ? (
        <Box height="80vh" display="flex" justifyContent="center" alignItems="center">
          <InputLoader size={7} />
        </Box>
      ) : (
        <>
          <AdditionalContactDetailsTable loading={isFetching} paginatedResponse={agentsContact} />
        </>
      )}
    </Box>
  )
}
