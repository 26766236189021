import React from 'react'
import Box from 'design/elements/Box'
import ComboBox from 'design/elements/ComboBox'
import TextField from 'design/elements/TextField'
import Panel from 'design/elements/Panel'
import Button from 'design/elements/Button'
import Spacer from 'design/elements/Spacer'
import { useQuery } from 'react-query'
import { fetchRejectReason } from 'agents/agents.api.client'

export default function RejectReasonModal({
  setRejectReason,
  rejectReason,
  rejectReasonDef,
  setRejectReasonDef,
  reject,
  refName,
  docStat,
  handleReject,
}) {
  const { data: _rejectReasonList = [], isFetching } = useQuery('reject-reasons', fetchRejectReason, {
    staleTime: Infinity,
  })
  const rejectReasonList = React.useMemo(() => {
    return _rejectReasonList?.map((item) => ({
      ...item,
      label: item,
      value: item,
    }))
  }, [_rejectReasonList])
  rejectReasonList.push({ label: 'Other', value: 'Other' })
  return (
    <Panel px="lg" py="lg">
      <Box height="300px">
        {!docStat ? (
          <>
            <ComboBox
              label="Select reject reason"
              options={rejectReasonList}
              loading={isFetching}
              onChange={(option) => {
                setRejectReasonDef(option.label)
              }}
              value={rejectReasonDef}
            />
            {rejectReasonDef === 'Other' ? (
              <TextField
                mt="sm"
                multiline
                label="Reject Reason"
                placeholder="Add rejection reason here"
                onChange={(e) => setRejectReason(e.target.value)}
              />
            ) : null}
            <Spacer mt="lg" />
            {rejectReasonDef === 'Other' ? (
              <Button fullWidth variant="blue" onClick={reject(refName, rejectReason)}>
                Reject
              </Button>
            ) : (
              <Button fullWidth variant="blue" onClick={reject(refName, rejectReasonDef)}>
                Reject
              </Button>
            )}
          </>
        ) : (
          <>
            <ComboBox
              label="Select reject reason"
              options={rejectReasonList}
              loading={isFetching}
              onChange={(option) => {
                setRejectReasonDef(option.label)
              }}
              value={rejectReasonDef}
            />
            {rejectReasonDef === 'Other' ? (
              <TextField
                mt="sm"
                multiline
                label="Reject Reason"
                placeholder="Add rejection reason here"
                onChange={(e) => setRejectReason(e.target.value)}
              />
            ) : null}
            <Spacer mt="lg" />
            {rejectReasonDef === 'Other' ? (
              <Button
                fullWidth
                variant="blue"
                onClick={() =>
                  handleReject({ agent_document_id: refName, document_status: 2, reject_reason: rejectReason })
                }
              >
                Reject
              </Button>
            ) : (
              <Button
                fullWidth
                variant="blue"
                onClick={() =>
                  handleReject({
                    agent_document_id: refName,
                    document_status: 2,
                    reject_reason: rejectReasonDef,
                  })
                }
              >
                Reject
              </Button>
            )}
          </>
        )}
      </Box>
    </Panel>
  )
}
