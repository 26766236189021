import * as React from 'react'
import { uploadKYC } from 'agents/agents.api.client'


export const useChangeID = (agent, selectedIDType, valueNumber, notify) => {

  const handleUpload = (file) => {
    uploadKYC({
      AgentId: agent.agent_id,
      IdentityType: selectedIDType,
      IdentityNumber: valueNumber || agent.identity_number,
      Identity: file,
    })
      .then((res) => {
        notify(res.data.result.response_message)
      })
      .catch((err) => {
        alert(err)
      })
  }

  return handleUpload
}

export const useChangeCACStatusReport = (agent, notify, history, setidfile) => {
  const handleUpload = (file) => {
    uploadKYC({
      AgentId: agent.agent_id,
      CACStatusReport: 'CACStatusReport',
      CACStatusReportFile: file,
    })
      .then((res) => {
        notify(res.data.result.response_message)
        setTimeout(() => history.go(0), 3000)
        setidfile(null)
      })
      .catch((err) => {
        alert(err)
      })
  }
  return handleUpload
}

export const useChangeCAC = (agent, notify) => {
    const handleUpload = (file) => {
        uploadKYC({
            AgentId: agent.agent_id,
            Cac: file,
        })
            .then((res) => {
                notify(res.data.result.response_message)
            })
            .catch((err) => {
                alert(err)
            })
    }
    return handleUpload
}

export const useChangePassport = (agent, notify,history) => {
  
    const handleUpload = (file) => {
      uploadKYC({
        AgentId: agent.agent_id,
        Passport: file,
      })
        .then((res) => {
          notify(res.data.result.response_message)
          setTimeout(() => history.go(0), 3000)
        })
        .catch((err) => {
          alert(err)
        })
    }

    return handleUpload
 
}

export const useChangeAddress = (agent, notify, history) => {
    const handleUpload = (file) => {
        uploadKYC({
            AgentId: agent.agent_id,
            EvidenceOfAddress: file,
        })
            .then((res) => {
                notify(res.data.result.response_message)
                setTimeout(() => history.go(0), 3000)
            })
            .catch((err) => {
                alert(err)
            })
    }
    return handleUpload
}


export const useChangeAllotmentOfShares = (agent, notify, history) => {
    const handleUpload = (file) => {
        uploadKYC({
            AgentId: agent.agent_id,
            AllotmentOfShares: 'AllotmentOfShares',
            AllotmentOfSharesFile: file,
        })
            .then((res) => {
                notify(res.data.result.response_message)
                setTimeout(() => history.go(0), 3000)
            })
            .catch((err) => {
                alert(err)
            })
    }

    return handleUpload
}

const changeParticularsOfProprietors = (agent,idfile,history,notify) => {
  uploadKYC({
    AgentId: agent.agent_id,
    ParticularsOfProprietorsForBusinessName: 'ParticularsOfProprietorsForBusinessName',
    ParticularsOfProprietorsForBusinessNameFile: idfile,
  })
    .then((res) => {
      notify(res.data.result.response_message)
      setTimeout(() => history.go(0), 3000)
    })
    .catch((err) => {
      alert(err)
    })
}

export const useChangeParticularsOfDirectors = (agent, notify, history) => {
    const handleUpload = (file) => {
        uploadKYC({
            AgentId: agent.agent_id,
            ParticularsOfDirectors: 'ParticularsOfDirectors',
            ParticularsOfDirectorsFile: file,
        })
            .then((res) => {
                notify(res.data.result.response_message)
                setTimeout(() => history.go(0), 3000)
            })
            .catch((err) => {
                alert(err)
            })
    }
    return handleUpload
}

export const useChangeBoardResolutionConsent = (agent, notify, history) => {
  const handleUpload = (file) => {
    uploadKYC({
      AgentId: agent.agent_id,
      BoardResolutionConsentWithFairMoney: 'board_resolution_consenting_to_with_fair_money',
      BoardResolutionConsentWithFairMoneyFile: file,
    })
      .then((res) => {
        notify(res.data.result.response_message)
        setTimeout(() => history.go(0), 3000)
      })
      .catch((err) => {
        alert(err)
      })
  }
  return handleUpload
}
export const useChangeEvidenceOfPOSActiveRate = (agent, notify, history) => {
    const handleUpload = (file) => {
        uploadKYC({
            AgentId: agent.agent_id,
            EvidenceOfPOSActiveRate: 'EvidenceOfPOSActiveRate',
            EvidenceOfPOSActiveRateFile: file,
        })
            .then((res) => {
                
                notify(res.data.result.response_message)
                setTimeout(() => history.go(0), 3000)
            })
            .catch((err) => {
                alert(err)
            })
    }
    return handleUpload
}

export const useChangeAggregatorProofOfEvidence = (agent, notify, history) => {
  const handleUpload = (file) => {
    uploadKYC({
      AgentId: agent.agent_id,
      AggregatorProofOfEvidence: 'AggregatorProofOfEvidence',
      AggregatorProofOfEvidenceFile: file,
    })
      .then((res) => {
        notify(res.data.result.response_message)
        setTimeout(() => history.go(0), 3000)
      })
      .catch((err) => {
        alert(err)
      })
  }
  return handleUpload
}

export const useChangeTransactionValueCommissionProof = (agent, notify, history) => {
  const handleUpload = (file) => {
    uploadKYC({
      AgentId: agent.agent_id,
      TransactionValueCommissionProof: 'TransactionValueCommissionProof',
      TransactionValueCommissionProofFile: file,
    })
      .then((res) => {
        notify(res.data.result.response_message)
        setTimeout(() => history.go(0), 3000)
      })
      .catch((err) => {
        alert(err)
      })
  }
  return handleUpload
}

export const useChangeCacCertifiedApplicationForm = (agent,history,notify) => {
    const handleUpload = (file) => {
        uploadKYC({
            AgentId: agent.agent_id,
            CacCertifiedApplicationForm: 'CacCertifiedApplicationForm',
            CacCertifiedApplicationFormFile: file,
        })
            .then((res) => {
                notify(res.data.result.response_message)
                setTimeout(() => history.go(0), 3000)
            })
            .catch((err) => {
                alert(err)
            })
    }
    return handleUpload
}


export const useChangeMermat = (agent, notify, history) => {
  const handleUpload = (file) => {
    uploadKYC({
      AgentId: agent.agent_id,
      MEMART: 'MEMART',
      MEMARTFile: file,
    })
      .then((res) => {
        notify(res.data.result.response_message)
        setTimeout(() => history.go(0), 3000)
      })
      .catch((err) => {
        alert(err)
      })
  }
  return handleUpload
}

export const useChangeIncorporatedTrusteesForm = (agent, notify, history) => {
  const handleUpload = (file) => {
    uploadKYC({
      AgentId: agent.agent_id,
      IncorporatedTrusteesApplicationForm: 'IncorporatedTrusteesApplicationForm',
      IncorporatedTrusteesApplicationFormFile: file,
    })
      .then((res) => {
        notify(res.data.result.response_message)
        setTimeout(() => history.go(0), 3000)
      })
      .catch((err) => {
        alert(err)
      })
  }
  return handleUpload
}

export const useChangeConstitution = (agent, notify, history) => {
  const handleUpload = (file) => {
    uploadKYC({
      AgentId: agent.agent_id,
      Constitution: 'Constitution',
      ConstitutionFile: file,
    })
      .then((res) => {
        notify(res.data.result.response_message)
        setTimeout(() => history.go(0), 3000)
      })
      .catch((err) => {
        alert(err)
      })
  }
  return handleUpload
}
