import * as React from 'react'
import { useForm } from 'react-hook-form'

/**
 * ? Local Imports
 */
import { useCampaignList } from 'campaigns/hooks/useCampaign'
import { useUpdateAgentRecruiterDetails } from 'agents/hooks/useUpdateAgent'

import Box from 'design/elements/Box'
import Button from 'design/elements/Button'
import Divider from 'design/elements/Divider'
import Select from 'design/elements/Select'
import Spacer from 'design/elements/Spacer'
import Text from 'design/elements/Text'
import TextField from 'design/elements/TextField'

import { loading } from 'lib/formatters'
import { ShowTree } from 'lib/gate'
import { roles } from 'lib/models'

const RecruiterForm = ({ agent, alert, clearAlert, notify }) => {
  const { setSearch, campaignGroupOptions, isFetching } = useCampaignList()
  const registering = React.useRef(false)
  const form = useForm({
    mode: 'onBlur',
    defaultValues: Object.assign({}, { campaign_id: 0 }),
  })

  const [updateRecruiterDetails, { status }] = useUpdateAgentRecruiterDetails({ alert, clearAlert, notify })

  const handleFormSubmit = React.useCallback(
    (_formValues) => {
      const _formBody = Object.assign({}, _formValues, {
        agent_id: agent?.agent_id,
      })
      updateRecruiterDetails(_formBody)
    },
    [agent, updateRecruiterDetails]
  )

  React.useEffect(() => {
    if (!registering.current) {
      form.register({ name: 'campaign_id', type: 'custom', required: true })
    }
    return () => {
      registering.current = false
    }
  }, [form])

  return (
    <Box as="form" onSubmit={form.handleSubmit(handleFormSubmit)}>
      <Text textTransform="uppercase" fontWeight="bold">
        Campaign
      </Text>
      <Divider />
      <TextField
        label="Campaign"
        name="campaign_name"
        defaultValue={agent?.campaign_name}
        placeholder="Search Campaign"
        alignItems="stretch"
        onChange={(e) => setSearch(e.target.value)}
      />
      <Spacer mt="md" />
      <Select
        options={campaignGroupOptions}
        onChange={(option) => form.setValue('campaign_id', option?.value)}
        loading={isFetching}
        label="Select an option"
        name="campaign_id"
      />

      {/* removed superadmin */}
      <ShowTree forRoles={[roles.OPPERATION_HEAD, roles.REGIONAL_RETENTION_MANAGER, roles.KYC_TEAM]}>
        <Button mt="lg" loading={loading(status)} type="submit" variant="success" fullWidth>
          Save
        </Button>
      </ShowTree>
    </Box>
  )
}

export default RecruiterForm
