import * as React from 'react'
import { useForm } from 'react-hook-form'

/**
 * ? Local Imports
 */
import { useUpdateAgentTransferLimit } from 'agents/hooks/useUpdateAgent'

import Box from 'design/elements/Box'
import Button from 'design/elements/Button'
import Spacer from 'design/elements/Spacer'
import TextField, { Label } from 'design/elements/TextField'
import { loading } from 'lib/formatters'
import { ShowTree } from 'lib/gate'

const SetTransactionLimitForm = ({ agent, alert, clearAlert, notify, roles }) => {
  const [updateTransferLimit, { status }] = useUpdateAgentTransferLimit({ alert, clearAlert, notify })

  const form = useForm({
    mode: 'onBlur',
    defaultValues: Object.assign(
      {},
      {
        upgrade_limit_to_highest: false,
      }
    ),
  })

  const registering = React.useRef(false)

  const handleFormSubmit = React.useCallback(
    (_formValues) => {
      const agentId = agent?.agent_id
      const Username = agent?.username
      const accountType = agent?.account_type
      const kycLevel = agent?.kyc_level

      const accountTypeString = String(accountType)

      const _formBody = Object.assign({}, _formValues, {
        agent_id: +agentId,
        agent_user_name: Username,
        account_type: accountTypeString,
        kyc_level: +kycLevel,
        daily_transaction_limit: Number.isFinite(+_formValues.daily_transaction_limit)
          ? +_formValues.daily_transaction_limit
          : +_formValues.daily_transaction_limit,
        max_transfer_limit: Number.isFinite(+_formValues.max_transfer_limit)
          ? +_formValues.max_transfer_limit
          : +_formValues.max_transfer_limit,
      })
      updateTransferLimit(_formBody)
    },
    [agent, updateTransferLimit]
  )

  return (
    <Box as="form" onSubmit={form.handleSubmit(handleFormSubmit)}>
      <Spacer mt="lg" />

      <TextField
        label="Set Transfer Limit"
        name="max_transfer_limit"
        error={form.errors?.max_transfer_limit}
        helperText={form.errors?.max_transfer_limit && 'Please enter a numeric value'}
        ref={form.register({ required: false, pattern: { value: /[\d]+$/ } })}
        defaultValue={agent?.max_transfer_limit}
      />
      <Spacer mt="lg" />
      <TextField
        label="Set Transaction Limit"
        name="daily_transaction_limit"
        error={form.errors?.daily_transaction_limit}
        helperText={form.errors?.daily_transaction_limit && 'Please enter a numeric value'}
        ref={form.register({ required: false, pattern: { value: /[\d]+$/ } })}
        defaultValue={agent?.daily_transaction_limit}
      />
      <Spacer mt="lg" />

      <ShowTree forRoles={[roles.OPPERATION_HEAD]}>
        <Button mt="lg" loading={loading(status)} type="submit" variant="success" fullWidth>
          Save
        </Button>
      </ShowTree>
    </Box>
  )
}

export default SetTransactionLimitForm
