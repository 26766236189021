import React, { Suspense } from 'react'
import Box from 'design/elements/Box'
import { useToast } from 'toast/ToastContext'
import { Switch, Route } from 'react-router-dom'
import PageLoader from 'design/PageLoader'
import withTitle from 'lib/withTitle'
import AgentKycList from './Panels/AgentKycList'
import KycEditWrapper from './Panels/KycEditWrapper'
import KycDocumentsList from './Panels/KycDocumentsList'

export default withTitle('Kyc', Kyc)

function Kyc() {
  const { alert, notify } = useToast()

  return (
    <Box maxWidth="100vw" overflowX="hidden">
      <Switch>
        <Route exact path="/kyc">
          <AgentKycList />
        </Route>
        <Route exact path={`/kyc/:agent_id/edit`}>
          <Suspense fallback={<PageLoader />}>
            <KycEditWrapper notify={notify} alert={alert} />
          </Suspense>
        </Route>
        <Route exact path={`/kyc/:agent_id/documentlist`}>
          <Suspense fallback={<PageLoader />}>
            <KycDocumentsList notify={notify} alert={alert} />
          </Suspense>
        </Route>
      </Switch>
    </Box>
  )
}
