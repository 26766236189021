import * as React from 'react'
import Panel from 'design/elements/Panel'
import Box from 'design/elements/Box'
import AgentTable from 'kyc/Tables/AgentKycTable'
import DateRangePicker from 'design/elements/DatePicker'
import { toNativeDate } from 'lib/formatters'
import Button from 'design/elements/Button'
import Spacer from 'design/elements/Spacer'
import Select from 'design/elements/Select'
import Text from 'design/elements/Text'
import { loading } from 'lib/formatters'
import TextField from 'design/elements/TextField'
import { useToggle } from 'lib/hooks/useToggle'
import Modal from 'design/elements/Modal'
import IconBtn from 'design/elements/IconBtn'
import Close from 'design/assets/images/close.svg'
import AssignReviewerModal from 'kyc/screens/AssignReviewerModal'
import useSyncHistoryWith from 'lib/hooks/syncHistoryWith'
import useAgentKycList from 'kyc/Hooks/useAgentKycList'

export default function AgentKycList() {
  const {
    handleFilter,
    filtersForm,
    status,
    dateRange,
    onDateChange,
    searchString,
    searchBy,
    searchOptions,
    handleExport,
    loadCause,
    isFetching,
    handleClear,
    kycLevelOptions,
    kycLevel,
    agentType,
    _agentTypes,
    reviwerStatus,
    _statusList,
    _orderList,
    orderByModifiedAt,
    users,
    currentReviewer,
    paginatedResponse,
    handlePageChange,
    query,
  } = useAgentKycList()

  const [reviewerModal, setReviewerModal] = useToggle(false)
  const [agentId, setAgentId] = React.useState('')
  const showModal = (cb) => {
    setReviewerModal()
    cb && cb()
  }

  useSyncHistoryWith(query)

  return (
    <>
      {reviewerModal && (
        <Modal close={setReviewerModal}>
          <Box width="500px" fontSize="sm">
            <Panel px="xl" py="md" display="flex" justifyContent="space-between" alignItems="center">
              <Text>Assign Reviewer </Text>
              <IconBtn onClick={setReviewerModal} color="#8b9ba8">
                <img src={Close} alt="close" />
              </IconBtn>
            </Panel>
            <AssignReviewerModal agentId={agentId} />
          </Box>
        </Modal>
      )}
      <Box onSubmit={handleFilter} ref={filtersForm} disabled={loading(status)}>
        <Panel display="flex" alignItems="center" px="md" py="md" justifyContent="space-between">
          <Box>
            <Text mb="0.2em" fontSize="small" color="highlight">
              Date Range
            </Text>
            <DateRangePicker
              initialDates={dateRange.current.map((d) => d && toNativeDate(d))}
              onStateChange={onDateChange}
            />
          </Box>
          <Box display="flex" gridGap="1em" alignItems="center">
            <Box>
              <TextField
                name="search"
                onChange={(e) => {
                  searchString.current = e.target.value
                }}
                label="Search"
                placeholder="Search"
                defaultValue={searchString.current}
              />
            </Box>
            <Box>
              <Select
                defaultValue={searchBy.current}
                onChange={(option) => {
                  searchBy.current = option.id
                }}
                valueField="id"
                label="Search By"
                name="searchBy"
                options={searchOptions}
                loading={!searchOptions}
              />
            </Box>
          </Box>
          <Box display="flex" gridGap="0.5em" alignItems="center" width="35%" mt="20px">
            <Button
              onClick={(e) => {
                e.preventDefault()
                handleExport()
              }}
              type="button"
              loader="Processing Export..."
              loading={['EXPORT'].includes(loadCause)}
              disabled={['EXPORT'].includes(loadCause) || isFetching}
              variant="dark"
              transparentBorder
              width="30%"
            >
              Export
            </Button>
            <Button
              type="button"
              onClick={(e) => {
                e.preventDefault()
                handleFilter()
              }}
              loading={loading(status) && ['SEARCH'].includes(loadCause)}
              variant="blue"
              transparentBorder
              width="30%"
            >
              Search
            </Button>
            <Button
              type="button"
              variant="restore"
              onClick={(e) => {
                e.preventDefault()
                handleClear()
              }}
              loading={loading(status) && ['SEARCH'].includes(loadCause)}
              transparentBorder
              Reset
              width="30%"
            >
              Reset
            </Button>
          </Box>
        </Panel>
        <Spacer mt="md" />

        <Panel px="xl" py="md" display="grid" gridTemplateColumns="repeat(4, 1fr)" gridGap="1em" alignItems="flex-end">
          <Select
            label="KYC Level"
            name="kycLevel"
            options={kycLevelOptions}
            loading={!kycLevelOptions.length}
            onChange={(option) => {
              kycLevel.current = option.value
            }}
            defaultValue={kycLevel.current}
          />
          <Select
            label="Agent type filter"
            name="agentTypes"
            options={_agentTypes}
            loading={!_agentTypes.length}
            onChange={(option) => {
              agentType.current = option.value
            }}
            defaultValue={agentType.current}
          />
          <Select
            label="Review status filter"
            name="reviewstatus"
            options={_statusList}
            loading={!_statusList.length}
            onChange={(option) => {
              reviwerStatus.current = option.value
            }}
            defaultValue={reviwerStatus.current}
          />
          <Select
            label="Order by filter"
            name="orderby"
            options={_orderList}
            loading={!_orderList.length}
            onChange={(option) => {
              orderByModifiedAt.current = option.value
            }}
            defaultValue={orderByModifiedAt.current}
          />
          <Select
            label="Current reviewer"
            name="currentreviewer"
            options={users}
            loading={!users.length}
            onChange={(option) => {
              currentReviewer.current = option.name
            }}
            defaultValue={currentReviewer.current}
          />
        </Panel>
      </Box>
      <AgentTable
        loading={isFetching}
        paginatedResponse={paginatedResponse}
        onPageChange={handlePageChange}
        showModal={showModal}
        setAgentId={setAgentId}
      />
    </>
  )
}
