import styled from 'styled-components'
import { color } from 'styled-system'

const MenuItem = styled.li`
  font-size: 12px;
  line-height: 1.33;
  text-align: left;
  display: block;
  padding: 1em 1.4em;
  color: #8b9ba8;
  cursor: pointer;
  transition: all 0.2s linear;
  pointer-events: ${(props) => (props.disabled ? 'none' : 'initial')};
  a {
    display: block;
    width: 100%;
    height: 100%;
  }
  &:hover {
    background-color: #f5f8fa;
  }
  ${color}
`
export default MenuItem
