import { useCallback, useState } from 'react'
import { useMutation } from 'react-query'
import { useToast } from '../toast/ToastContext'
import { rejectKYC, acceptKYC, updateAgentDocumentStatus } from './agents.api.client'
import { useHistory } from 'react-router-dom/cjs/react-router-dom'

export function useRejectKYC(onSuccess, onError) {
  const { notify, alert } = useToast()
  const [rejecting, setRejecting] = useState(null)
  const [mutate, { status }] = useMutation(rejectKYC, {
    onError(err) {
      alert(err)
      typeof onError === 'function' && onError(err)
    },
    onSettled() {
      setRejecting(null)
    },
  })

  const run = useCallback(
    ({ agentid, ref, rejectReason }) => {
      setRejecting(ref)
      mutate(
        { agentid, ref, rejectReason },
        {
          onSuccess(res) {
            notify(res)
            typeof onSuccess === 'function' && onSuccess(res, ref)
          },
        }
      )
    },
    [mutate, notify, onSuccess]
  )

  return { run, status, rejecting }
}

export function useAcceptKYC(onSuccess, onError) {
  const { notify, alert } = useToast()
  const [accepting, setAccepting] = useState(null)
  const [mutate, { status }] = useMutation(acceptKYC, {
    onError(err) {
      alert(err)
      typeof onError === 'function' && onError(err)
    },
    onSettled() {
      setAccepting(null)
    },
  })

  const accepts = useCallback(({ agentid, ref }) => {
    setAccepting(ref)
    mutate(
      { agentid, ref },
      {
        onSuccess(res) {
          notify(res)
          typeof onSuccess === 'function' && onSuccess(res, ref)
        },
      }
    )
  })

  return { accepts, status, accepting }
}

export const useUpdateDocumentStatus = () => {
  const { notify, alert } = useToast()
  const history = useHistory()
  const [mutate, { data, isLoading }] = useMutation(updateAgentDocumentStatus, {
    onError(err) {
      alert(err)
    },
    onSuccess(res) {
      notify(res)
      setTimeout(() => history.go(0), 3000)
    },
  })

  const handleAccept = useCallback((params) => mutate({ ...params }), [mutate])
  const handleReject = useCallback((params) => mutate({ ...params }), [mutate])
  return { handleAccept, handleReject, data, isLoading }
}
