import React from 'react'
import styled from 'styled-components'
import Box from './Box'
import { getRandomId } from './TextField'

const Checkbox = (
  {
    label = 'Default label',
    defaultChecked,
    checked,
    onChange,
    name,
    inputProps = {},
    id = getRandomId(),
    ...styleProps
  },
  ref
) => {
  return (
    <Box display="flex" {...styleProps} alignItems="center">
      <StyledCheckbox
        name={name}
        type="checkbox"
        onChange={onChange}
        checked={checked}
        defaultChecked={defaultChecked}
        ref={ref}
        id={id}
        {...inputProps}
      />
      <label htmlFor={id}>{label}</label>
    </Box>
  )
}
const StyledCheckbox = styled.input`
  position: absolute;
  opacity: 0;
  & + label {
    position: relative;
    font-size: ${(props) => (props.small ? '10px' : '12px')};
    cursor: pointer;
    padding: 0;
  }

  // Box.
  & + label:before {
    content: '';
    margin-right: 10px;
    display: inline-block;
    vertical-align: text-bottom;
    width: 12px;
    height: 12px;
    border: solid 1px #dbe0e5;
    background: white;
  }

  // Box hover
  &:hover + label:before {
    background: ${(props) => props.theme.colors.highlight};
    border-color: transparent;
  }

  // Box focus
  &:focus + label:before {
    box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
  }

  // Box checked
  &:checked + label:before {
    background: ${(props) => props.theme.colors.highlight};
    border-color: transparent;
  }

  // Disabled state label.
  &:disabled + label {
    color: ${(props) => props.theme.colors.text};
    cursor: auto;
  }

  // Disabled box.
  &:disabled + label:before {
    box-shadow: none;
    background: ${(props) => props.theme.colors.text};
  }

  // Checkmark. Could be replaced with an image
  &:checked + label:after {
    content: '';
    position: absolute;
    left: 2px;
    top: 5px;
    background: white;
    width: 2px;
    height: 2px;
    box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white, 4px -6px 0 white, 4px -8px 0 white;
    transform: rotate(45deg);
  }
`

export default React.forwardRef(Checkbox)
