import * as React from 'react'
import { useForm } from 'react-hook-form'
import { useQuery, useMutation } from 'react-query'

/**
 * ? Local Imports
 */
import { useAgentTypes, useAgentStatuses } from 'agents/Agents.Context'
import { useAuthState } from 'auth/context/Auth.Context'
import { useUpdateAgentInfoDetails } from 'agents/hooks/useUpdateAgent'

import { getAllApps, mapAppToAgent } from 'apiUsersManagement/apiUserMgt.api.client'
import { fetchAccoountTypes } from 'transactions/transactions.api.client'

import Box from 'design/elements/Box'
import Button from 'design/elements/Button'
import Checkbox from 'design/elements/Checkbox'
import ComboBox from 'design/elements/ComboBox'
import Divider from 'design/elements/Divider'
import Select from 'design/elements/Select'
import Spacer from 'design/elements/Spacer'
import Text from 'design/elements/Text'
import { AGENTTYPE_VIEW } from 'lib/models'

import { loading } from 'lib/formatters'
import { ShowTree } from 'lib/gate'
import { roles } from 'lib/models'
import { fetchAgentTypes } from 'agents/agents.api.client'

const AgentInfoDetails = ({ agent, agentId, alert, clearAlert, notify }) => {
  const { data: _accountTypes = [] } = useQuery('account-types', fetchAccoountTypes, {
    staleTime: Infinity,
  })

  const { user } = useAuthState()

  const truthy =
    user?.user_role === roles.SUPER_ADMIN ||
    user?.user_role === roles.ADMIN ||
    user?.user_role === roles.OPPERATION_HEAD

  const { data: appData = [], getAppStatus } = useQuery(['fetchApp'], () => (truthy ? getAllApps() : null), {
    staleTime: 5 * 60 * 1000,
  })

  const { data: agentTypes = [] } = useQuery('agent-types', fetchAgentTypes, {
    staleTime: Infinity,
  })
  const agentStatuses = useAgentStatuses(['all'])

  const form = useForm({
    mode: 'onBlur',
    defaultValues: Object.assign(
      {},
      { change_agent_type: false },
      { change_account_type: false },
      { change_agent_status: false },
      { app_id: 0 },
      { account_type: 0 },
      { agent_status: 0 },
      { agent_type: 0 }
    ),
  })

  const [updateInfoDetails, { status }] = useUpdateAgentInfoDetails({ alert, clearAlert, notify })

  const registering = React.useRef(true)
  const [appId, setAppId] = React.useState('')
  const [change_agent_status, allowChangeStatus] = React.useState(false)
  const [change_agent_type, allowChangeType] = React.useState(false)
  const [change_account_type, allowAccountType] = React.useState(false)

  const handleFieldChange = React.useCallback(
    (field) => {
      return (option) => form.setValue(field, option?.value)
    },
    [form]
  )

  const handleUpdateForm = React.useCallback(
    (_formValues) => {
      // form.setValue('change_agent_type', change_agent_type)
      // form.setValue('change_account_type', change_account_type)
      // form.setValue('change_agent_status', change_agent_status)

      form.setValue('app_id', appId === '' ? 0 : appId)
      const agentId = agent?.agent_id
      const _body = Object.assign({}, _formValues, {
        agent_id: +agentId,
        is_api_app_agent: agent?.is_api_app_agent,
        account_type: +_formValues.account_type,
        agent_type: +_formValues.agent_type,
        change_account_type: change_account_type,
        change_agent_type: change_agent_type,
        change_agent_status: change_agent_status,
        agent_status: +_formValues.agent_status,
      })
      updateInfoDetails(_body)
    },
    [appId, agent, change_account_type, change_agent_status, change_agent_type, form, updateInfoDetails]
  )

  const [mutate, { status: saveStatus }] = useMutation(mapAppToAgent, {
    onError(err) {
      alert(err || 'There was a problem assigning the sub agent(s)')
    },
  })

  // getApplist for render
  const appList = appData?.map((app) => ({ label: app.app_name, value: app.app_id }))

  const handleMapSubmit = React.useCallback(() => {
    if (appId === '' || agentId === '') {
      return alert('Please select an app')
    }

    mutate(
      {
        agent_id: Number(agentId),
        app_id: appId,
      },
      {
        onSuccess(message) {
          setAppId(false)
          notify(message || 'The agent have been successfully Mapped to App', 'Operation Successful')
        },
      }
    )
  }, [appId, agentId, alert, mutate, notify])

  React.useEffect(() => {
    if (registering.current) {
      form.register({ name: 'agent_type', required: true, type: 'custom' })
      form.register({ name: 'account_type', required: true, type: 'custom' })
      form.register({ name: 'agent_status', required: true, type: 'custom' })
      form.register({ name: 'change_agent_type', required: false, type: 'custom' })
      form.register({ name: 'change_account_type', required: false, type: 'custom' })
      form.register({ name: 'change_agent_status', required: false, type: 'custom' })
      form.register({ name: 'app_id', required: false, type: 'custom' })
    }

    return () => {
      registering.current = false
    }
  }, [form])

  return (
    <Box as="form" onSubmit={form.handleSubmit(handleUpdateForm)}>
      <Text textTransform="uppercase" fontWeight="bold">
        Agent Information Details
      </Text>
      <Divider />
      <Spacer mt="lg" />
      <Checkbox
        label="Change Account Type"
        defaultChecked={change_account_type}
        onChange={() => allowAccountType((c) => !c)}
        name="change_account_type"
        id="change_account_type"
      />

      <Spacer mt="lg" />
      <Select
        label="Account Type"
        disabled={!change_account_type}
        options={_accountTypes}
        loading={!_accountTypes.length}
        defaultValue={form.getValues().account_type || '' + agent?.account_type}
        onChange={handleFieldChange('account_type')}
      />

      <Spacer mt="lg" />
      <Spacer mt="lg" />
      <Checkbox
        label="Change Agent Status"
        defaultChecked={change_agent_status}
        onChange={() => allowChangeStatus((c) => !c)}
        name="change_agent_status"
        id="change_agent_status"
      />

      <Spacer mt="lg" />
      <Select
        label="Status"
        options={agentStatuses}
        loading={!agentStatuses.length}
        defaultValue={form.getValues().agent_status || '' + agent?.agent_status}
        onChange={handleFieldChange('agent_status')}
        disabled={!change_agent_status}
        labelField="name"
        valueField="value"
      />

      <Spacer mt="lg" />
      <Checkbox
        label="Change Agent Type"
        defaultChecked={change_agent_type}
        onChange={() => allowChangeType((c) => !c)}
        name="change_agent_type"
        id="change_agent_type"
      />

      <Spacer mt="lg" />
      <Select
        label="Agent Type"
        disabled={!change_agent_type}
        options={agentTypes}
        loading={!agentTypes.length}
        defaultValue={form.getValues().agent_type || '' + agent?.agent_type}
        onChange={handleFieldChange('agent_type')}
      />
      <Spacer mt="lg" />

      <Text textTransform="uppercase" fontWeight="bold">
        Map Agent to App
      </Text>
      <Box py="lg">
        <ComboBox
          onChange={(agent) => setAppId(agent?.value)}
          options={appList}
          loading={loading(getAppStatus)}
          label="Select App"
          placeholder="Choose an app"
        />
        <Spacer mt="xl" />
        <Button
          loader="Processing..."
          loading={loading(saveStatus)}
          type="submit"
          variant="blue"
          ml="auto"
          display="block"
          fullWidth
          onClick={(e) => {
            e.preventDefault()
            handleMapSubmit()
          }}
        >
          Map
        </Button>
      </Box>
      <Spacer mt="lg" />

      <Text>CHECKS </Text>
      <Divider mt="0" />
      <Spacer mt="md" />
      <Checkbox
        defaultChecked={agent?.has_been_trained}
        ref={form.register}
        name="has_been_trained"
        id="has_been_trained"
        label="Agent has been trained"
      />
      <Spacer mt="sm" />
      <Spacer mt="sm" />
      <Checkbox
        label="Agent has filled POS Agreement form"
        defaultChecked={agent?.has_filled_pos_agreement_form}
        ref={form.register}
        name="has_filled_pos_agreement_form"
        id="has_filled_pos_agreement_form"
      />
      <Spacer mt="sm" />
      <Checkbox label="Send email" defaultChecked={false} ref={form.register} name="send_email" id="send_email" />

      <ShowTree
        forRoles={[
          roles.OPPERATION_HEAD,
          roles.REGIONAL_RETENTION_MANAGER,
          roles.SUPPORT,
          roles.KYC_TEAM,
          AGENTTYPE_VIEW.includes(user?.agent_username.toLowerCase()) && roles.POS_TEAM,
        ]}
      >
        <Button mt="xl" loading={loading(status)} type="submit" variant="success" fullWidth>
          Save
        </Button>
      </ShowTree>
    </Box>
  )
}

export default AgentInfoDetails
