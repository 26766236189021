import React from 'react'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import { Input, Label } from 'design/elements/TextField'
import 'react-day-picker/lib/style.css'
import Box from 'design/elements/Box'
import Spacer from 'design/elements/Spacer'
import styled from 'styled-components'

const StyledDayPicker = styled(Box)`
  position: relative;
  span.form-helper-text {
    font-size: x-small;
    display: block;
    height: 20px;
    padding: 0.5em 0;
    transition: all 0.2s linear;
    position: absolute;
    max-width: 100%;
  }
  span.form-helper-text.invalid {
    color: ${(props) => props.theme.colors.red};
  }
  .DayPickerInput {
    width: 100%;

    input {
      font-family: inherit;
      border: 1px solid ${(props) => (props.error ? props.theme.colors.red : 'initial')};
    }
  }
  .DayPicker-TodayButton {
    color: ${(props) => props.theme.colors.highlight};
  }
`
export default React.forwardRef(function DayPicker(props, ref) {
  const { label, error, value, onChange, onBlur, helperText, ...otherProps } = props

  return (
    <StyledDayPicker error={error} {...otherProps}>
      {label && (
        <>
          <Label small={otherProps.small}>{label}</Label>
          <Spacer mt={otherProps.small ? '0.3em' : 'xs'} />
        </>
      )}
      <DayPickerInput value={value} onBlur={onBlur} onDayChange={onChange} component={Input} />
      <span className={['form-helper-text', error && 'invalid'].filter(Boolean).join(' ')}>{helperText}</span>
    </StyledDayPicker>
  )
})
