import React, { useState } from 'react'
import { useLocation, useHistory, useParams } from 'react-router-dom'
import { useQuery } from 'react-query'
import { fetchAgentKycDocuments } from 'kyc/Kyc.api.client'
import { useToggle } from 'lib/hooks/useToggle'

export function useAgentKycDocumentData() {
  const { agent_id } = useParams()
  const history = useHistory()
  const location = useLocation()
  const agentInformation = location.state.context

  const {
    data: agentDocumentsDetails,
    status,
    isFetching,
  } = useQuery(['agent_documents_details', agent_id], (_, id) => fetchAgentKycDocuments(id), {
    staleTime: 5 * 60 * 1000,
    notifyOnStatusChange: false,
  })

  const [documentHistoryModal, setDocumentHistoryModal] = useToggle(false)
  const [agentId, setAgentId] = useState('')
  const [agentDocumentId, setAgentDocumentId] = useState('')
  const [documentType, setDocumentType] = useState('')

  const showModal = () => {
    setDocumentHistoryModal()
  }

  return {
    agent_id,
    history,
    agentInformation,
    agentDocumentsDetails,
    status,
    isFetching,
    documentHistoryModal,
    setDocumentHistoryModal,
    agentId,
    setAgentId,
    agentDocumentId,
    setAgentDocumentId,
    documentType,
    setDocumentType,
    showModal,
  }
}
