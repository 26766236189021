import { useQuery } from 'react-query'

/**
 * ? Local Imports
 */
import { fetchAllBanks } from 'agents/agents.api.client'

export function useAllBanks() {
  const { data, status, isFetching } = useQuery('fetch-all-nigerian-banks', fetchAllBanks, {
    staleTime: 20 * 60 * 1000,
    notifyOnStatusChange: false,
  })

  return [{ data, isFetching, status }]
}
