import React from 'react'
import FullTable from 'design/elements/FullTable'
import TR from 'design/elements/TR'
import TD from 'design/elements/TD'
import Elippsis from 'design/icons/Elippsis'
import MenuItem from 'design/elements/MenuItem'
import TH from 'design/elements/TH'
import Text from 'design/elements/Text'
import { formatDateTime } from 'lib/formatters'
import Menu from 'design/elements/Menu'
import { kycDocumentStatusColors } from 'lib/TableStatusColorIndicators'

export default function AgentKycDocument({
  Documents,
  loading,
  showModal,
  setAgentId,
  setAgentDocumentId,
  setDocumentType,
}) {
  return (
    <FullTable
      elements={Documents}
      RenderHeaders={RenderHeaders}
      RenderRow={(props) => RenderRow({ ...props })}
      loading={loading}
      RenderMenu={(...props) => RenderMenu(...props, showModal, setAgentId, setAgentDocumentId, setDocumentType)}
      RenderNoData={<h3>There are no documents uploaded</h3>}
    />
  )
}

const RenderRow = ({ row: agent, triggerMenu }) => {
  return (
    <TR>
      <TD>
        <Text fontWeight={500} textAlign="center">
          {agent.document_type}
        </Text>
      </TD>
      <TD>
        <Text fontWeight={500}>{formatDateTime(agent.created_at) || '-'}</Text>
      </TD>

      <TD>
        <Text fontWeight={500} color={kycDocumentStatusColors[agent.document_status]} textAlign="center">
          {agent.document_status}
        </Text>
      </TD>
      <TD>
        <Text fontWeight={500} textAlign="center">
          {formatDateTime(agent.modified_at)}
        </Text>
      </TD>
      <TD>
        <Text fontWeight={500} textAlign="center">
          {agent.modified_by || '-'}
        </Text>
      </TD>
      <TD textAlign="center" borderLeft="1px solid #e3ecf4">
        <button onClick={triggerMenu}>
          <Elippsis />
        </button>
      </TD>
    </TR>
  )
}

const RenderMenu = (context, closeMenu, popperProps, showModal, setAgentId, setAgentDocumentId, setDocumentType) => {
  if (!context) {
    return null
  }
  return (
    <Menu {...popperProps()} id="menu1" role="menu" tabIndex={0} aria-labelledby="menubutton">
      <MenuItem
        onClick={() => {
          setAgentId(context?.agents_id)
          setAgentDocumentId(context?.agent_document_id)
          setDocumentType(context?.document_type)
          closeMenu()
          showModal()
        }}
      >
        Document History
      </MenuItem>
    </Menu>
  )
}

const RenderHeaders = () => (
  <tr>
    <TH textAlign="center">DOCUMENT TYPE</TH>
    <TH textAlign="center">UPLOAD DATE</TH>
    <TH textAlign="center">APPROVAL STATUS</TH>
    <TH textAlign="center">APPROVED DATE & TIME</TH>
    <TH textAlign="center"> MODIFIED BY</TH>
    <TH textAlign="center" />
  </tr>
)
