export const statusColors = {
  Approved: 'green',
  Rejected: 'red',
  Uploaded: 'orange',
  Verified: 'green',
  Unverified: 'red',
  Treated: 'green',
  Pending: 'red',
  'In Progress': 'orange',
  true: 'green',
  false: 'gray',
  Active: 'green',
  inActive: 'gray',
  Churn: 'red',
}

export const kycDocumentStatusColors = {
  Approved: 'green',
  Rejected: 'red',
  Pending: 'orange',
}
