import React from 'react'
import UploadButton from 'design/elements/UploadButton'
import Modal from 'design/elements/Modal'
import Button from 'design/elements/Button'
import Box from 'design/elements/Box'
import Panel from 'design/elements/Panel'
import Close from 'design/assets/images/close.svg'
import {useToggle} from 'lib/hooks/useToggle'
import IconBtn from 'design/elements/IconBtn'
import Text from 'design/elements/Text'
import { getRand } from 'lib/formatters'

export default function SheetUploader({
  children,
  fileName,
  accept,
  label,
  loading,
  saveFn,
  onCancel,
  disabled = false,
  disableSave,
  noButtons,
  title,
  variant,
  
  saveText = 'Save',
}) {
  const [showDialog, toggleDialog] = useToggle(false)
  const file = React.useRef()
  const randomNumber = React.useRef(getRand())

  const handleFileChange = React.useCallback(
    (e) => {
      file.current = e.target.files[0]
      toggleDialog(true)
      randomNumber.current = randomNumber.current + getRand()
    },
    [toggleDialog]
  )

  const handleSave = React.useCallback(
    (e) => {
      e.preventDefault()
      saveFn &&
        saveFn(file.current, function closeModal() {
          toggleDialog(false)
          file.current = null
        })
    },
    [saveFn, toggleDialog]
  )

  const handleCancel = React.useCallback(() => {
    toggleDialog(false)
    file.current = null
    onCancel && onCancel()
  }, [toggleDialog, onCancel])

  return (
    <>
      <UploadButton
        key={randomNumber.current}
        onChange={handleFileChange}
        fileName={fileName}
        accept={accept}
        disabled={disabled}
        variant={variant || 'default'}
      >
        {label || 'Upload Spreadsheet'}
      </UploadButton>
      {showDialog && file.current && (
        <FileDialog
          title={title}
          disable={disableSave}
          loading={loading}
          close={toggleDialog}
          file={file.current}
          onSave={handleSave}
          onCancel={handleCancel}
          saveText={saveText}
          noButtons={noButtons}
        >
          {children}
        </FileDialog>
      )}
    </>
  )
}

function FileDialog({
  close,
  file,
  onSave,
  onCancel,
  loading = false,
  children,
  saveText,
  title,
  disable = false,
  noButtons,
  width
}) {
  return (
    <Modal close={close}>
      <Box width={width || 400} fontSize="sm">
        <Panel px="xl" py="md" display="flex" justifyContent="space-between" alignItems="center">
          <Text color="highlight" fontWeight="bold">
            {title || 'FILE UPLOAD'}
          </Text>

          <IconBtn onClick={close} color="#8b9ba8">
            <img src={Close} alt="" />
          </IconBtn>
        </Panel>
        <Panel
          px="xl"
          pt="lg"
          pb="md"
          as="form"
          display="flex"
          flexDirection="column"
          minHeight="200px"
          onSubmit={onSave}
        >
          <Box flex="1">
            {!children ? (
              <Text color="highlight" fontWeight="bold">
                {file.name}{' '}
                <Box as="sub" fontSize="x-small">
                  <u>{(file.size / 1000).toFixed(2)} Kb</u>
                </Box>
              </Text>
            ) : typeof children === 'function' ? (
              children(file)
            ) : (
              children
            )}
          </Box>
          {!noButtons && (
            <Box mt="md" mx="sm" display="grid" gridTemplateColumns="30% 1fr 40%">
              <Button disabled={loading} variant="default" fullWidth onClick={onCancel} type="button">
                Cancel
              </Button>
              <Box />
              <Button loading={loading} disabled={disable} variant="success" fullWidth type="submit">
                {saveText}
              </Button>
            </Box>
          )}
        </Panel>
      </Box>
    </Modal>
  )
}
