import React from 'react'
import useQueryParams from 'lib/hooks/useQueryParams'
import { useQuery } from 'react-query'
import {
  fetchAgentKycStatusReport,
  fetchReviewerStatusList,
  fetchOrderList,
  fetchKycUsers,
  fetchAgentKycLevels,
} from 'kyc/Kyc.api.client'
import { fetchAgentTypes, getSearchByList } from 'agents/agents.api.client'
import { useForm } from 'react-hook-form'
import { toNativeDate } from 'lib/formatters'
import useSyncHistoryWith from 'lib/hooks/syncHistoryWith'
import { useToast } from 'toast/ToastContext'

export default function useAgentKycList() {
  const { alert, notify, clearAlert } = useToast()
  const initialState = {
    pageIndex: 1,
    pageSize: 10,
    searchString: '',
    searchBy: '',
    accounttype: '',
    kycLevel: '',
    export: 'false',
    dateRangeFrom: null,
    dateRangeTo: null,
    agentType: '',
    reviwerStatus: '',
    orderByModifiedAt: '',
    currentReviewer: '',
  }
  const queryParams = useQueryParams()
  const [query, setQuery] = React.useState(queryParams)
  const [loadCause, setLoadCause] = React.useState(initialState)

  const filtersForm = React.useRef()
  const pageIndexRef = React.useRef(queryParams.pageIndex | 1)
  const searchString = React.useRef(queryParams.searchString)
  const searchBy = React.useRef(queryParams.searchBy)
  const accounttype = React.useRef(queryParams.accounttype)
  const kycLevel = React.useRef(queryParams.kycLevel)
  const agentType = React.useRef(queryParams.agentType)
  const reviwerStatus = React.useRef(queryParams.reviwerStatus)
  const orderByModifiedAt = React.useRef(queryParams.orderByModifiedAt)
  const currentReviewer = React.useRef(queryParams.currentReviewer)
  const dateRangeTo = React.useRef(queryParams.dateRangeTo)
  const dateRangeFrom = React.useRef(queryParams.dateRangeFrom)
  const {
    data: paginatedResponse,
    status,
    isFetching,
  } = useQuery(
    ['fetch-Agent-Kyc-StatusReport-list', query],
    (_, q) =>
      fetchAgentKycStatusReport({
        pageIndex: q.pageIndex || initialState.pageIndex,
        pageSize: q.pageSize || initialState.pageSize,
        searchString: q.searchString || initialState.searchString,
        searchBy: q.searchBy || initialState.searchBy,
        accounttype: q.accounttype || initialState.accounttype,
        kycLevel: q.kycLevel || initialState.kycLevel,
        agentType: q.agentType || initialState.agentType,
        reviwerStatus: q.reviwerStatus || initialState.reviwerStatus,
        orderByModifiedAt: q.orderByModifiedAt || initialState.orderByModifiedAt,
        reviwerUsername: q.currentReviewer || initialState.currentReviewer,
        dateRangeTo: q.dateRangeTo || initialState.dateRangeTo,
        dateRangeFrom: q.dateRangeFrom || initialState.dateRangeFrom,
        export: q.export || initialState.export,
      }),
    {
      staleTime: 60 * 60 * 1000,
    }
  )

  const handlePageChange = (newPage) => {
    setQuery((q) => ({ ...q, pageIndex: newPage }))
  }
  const handleFilter = React.useCallback(() => {
    setLoadCause('SEARCH')
    pageIndexRef.current = 1
    setQuery((query) => ({
      ...query,
      searchBy: searchBy.current,
      searchString: searchString.current,
      accounttype: accounttype.current,
      kycLevel: kycLevel.current,
      agentType: agentType.current,
      reviwerStatus: reviwerStatus.current,
      orderByModifiedAt: orderByModifiedAt.current,
      currentReviewer: currentReviewer.current,
      export: 'false',
      dateRangeFrom: dateRange.current[0],
      dateRangeTo: dateRange.current[1],
      pageIndex: 1,
    }))
  }, [])

  const { data: _agentTypes = [] } = useQuery('agent-types', fetchAgentTypes, { staleTime: Infinity })
  const { data: _statusList = [] } = useQuery('status-list', fetchReviewerStatusList, { staleTime: Infinity })
  const { data: _orderList = [] } = useQuery('order-list', fetchOrderList, { staleTime: Infinity })
  const { data: _users = [] } = useQuery(`fetch-kyc-users`, fetchKycUsers)
  const users = React.useMemo(() => {
    return _users?.map((item) => ({
      ...item,
      name: item.user_name,
      value: item.id,
    }))
  }, [_users])

  const { data: searchOptions } = useQuery(`seachby-list`, getSearchByList)
 const { data: _kycLevelOptions = [] } = useQuery(`fetch-agent-kyc-levels`, fetchAgentKycLevels)
 const kycLevelOptions = React.useMemo(() => {
   return _kycLevelOptions?.map((item) => ({
     ...item,
     name: item.name,
     value: String(item.value),
   }))
 }, [_kycLevelOptions])

  const handleClear = React.useCallback((e) => {
    pageIndexRef.current = 1
    setQuery((query) => ({
      ...query,
      pageIndex: 1,
      pageSize: 10,
      searchString: '',
      searchBy: '',
      accounttype: '',
      kycLevel: '',
      export: 'false',
      dateRangeFrom: null,
      dateRangeTo: null,
      agentType: '',
      reviwerStatus: '',
      orderByModifiedAt: '',
      currentReviewer: '',
    }))
  }, [])
  const handleExport = React.useCallback(() => {
    setLoadCause('EXPORT')
    clearAlert()
    fetchAgentKycStatusReport({
      ...query,
      searchBy: searchBy.current,
      searchString: searchString.current,
      accounttype: accounttype.current,
      kycLevel: kycLevel.current,
      agentType: agentType.current,
      reviwerStatus: reviwerStatus.current,
      orderByModifiedAt: orderByModifiedAt.current,
      currentReviewer: currentReviewer.current,
      dateRangeFrom: dateRange.current[0],
      dateRangeTo: dateRange.current[1],
      export: 'true',
      pageIndex: 1,
    })
      .then(() => notify(`The exported file has been sent to your email address`))
      .catch(() => alert(`There was a problem processing your request`))
      .finally(() => setLoadCause(null))
  }, [alert, clearAlert, notify, query])
  const dateRange = React.useRef(!!accounttype ? [accounttype.start_date, accounttype.end_date] : [null, null])

  const form = useForm({
    mode: 'onBlur',
    defaultValues: Object.assign({}, { start_date: dateRange.current[0], end_date: dateRange.current[1] }, accounttype),
  })

  const onDateChange = React.useCallback(
    ([start, end]) => {
      dateRange.current = [start, end]
      form.setValue('start_date', toNativeDate(start).toISOString())
      form.setValue('end_date', toNativeDate(end).toISOString())
    },
    [form]
  )

  useSyncHistoryWith(query)
  return {
    handleFilter,
    filtersForm,
    status,
    dateRange,
    onDateChange,
    searchString,
    searchBy,
    searchOptions,
    handleExport,
    loadCause,
    isFetching,
    handleClear,
    kycLevelOptions,
    kycLevel,
    agentType,
    _agentTypes,
    reviwerStatus,
    _statusList,
    _orderList,
    orderByModifiedAt,
    users,
    currentReviewer,
    paginatedResponse,
    handlePageChange,
    query,
  }
}
