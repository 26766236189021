import React, { useState, useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { useMutation } from 'react-query'
import { updateAdditionalContactdetails } from 'agents/agents.api.client'

export const useContactDetailsForm = ({ agent, details = {}, notify, alert }) => {
  const castNumber = (v) => (Number.isFinite(+v) ? Number(v) : NaN)
  const [data, setData] = useState(details)

  const { register, errors, reset } = useForm({
    mode: 'onBlur',
    defaultValues: { date_of_birth: agent?.date_of_birth },
  })

  const [mutate, { status }] = useMutation(updateAdditionalContactdetails, {
    onSuccess(response) {
      notify(response)
      reset()
      setData((prevData) => ({ ...prevData, agent_contact_type: null, agent_contact_info: '' }))
    },
    onError(error) {
      alert(error)
    },
  })

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault()
      const agentId = agent?.agent_id
      const body = {
        id: castNumber,
        agent_id: agentId,
        agent_contact_type: data.agent_contact_type,
        agent_contact_info: data.agent_contact_info,
      }
      mutate(body, {
        onError(err) {
          alert(`There was a problem performing your request`)
        },
        onSuccess(res) {
          notify(res || `Operation successful!`)
        },
      })
    },
    [data, alert, notify, agent, mutate]
  )

  return [register, errors, status, handleSubmit, data, setData]
}
