import * as React from 'react'
import { useForm } from 'react-hook-form'
import dayjs from 'dayjs'

/**
 * ? Local Imports
 */
import { useUpdateAgentBasicDetails } from 'agents/hooks/useUpdateAgent'

import Box from 'design/elements/Box'
import Button from 'design/elements/Button'
import Divider from 'design/elements/Divider'
import Spacer from 'design/elements/Spacer'
import Text from 'design/elements/Text'
import TextField, { Label } from 'design/elements/TextField'
import { DisableTree } from 'lib/gate/GateComponents'
import { loading } from 'lib/formatters'
import { ShowTree } from 'lib/gate'
import DownloadIcon from 'design/icons/download'
import { formatDateTime } from 'lib/formatters'

const BasicDetailsForm = ({ agent, roles, notify, alert, clearAlert }) => {
  const form = useForm({
    mode: 'onBlur',
    defaultValues: Object.assign({}, { date_of_birth: agent?.date_of_birth }),
  })

  const registering = React.useRef(false)
  const [updateBasicDetails, { status }] = useUpdateAgentBasicDetails({ notify, alert, clearAlert })
  // const [dob, setDob] = React.useState(agent?.date_of_birth?.split('T')[0])
  const [selectedDay, setSelectedDay] = React.useState('')

  const updateForm = React.useCallback(
    (_formValues) => {
      const agentId = agent?.agent_id
      const _formBody = Object.assign({}, _formValues, {
        agent_id: +agentId,

        date_of_birth: _formValues.date_of_birth || selectedDay,
      })

      updateBasicDetails(_formBody)
    },
    [agent, updateBasicDetails, selectedDay]
  )

  React.useEffect(() => {
    if (!registering.current) {
      form.register({ name: 'date_of_birth', type: 'custom', required: true })
    }
    return () => {
      registering.current = false
    }
  }, [form])

  React.useEffect(() => {
    if (agent?.date_of_birth) {
      setSelectedDay((agent?.date_of_birth).substring(0, 10))
    }
  }, [agent?.date_of_birth])

  const renderCustomInput = () => (
    <input
      type={'date'}
      placeholder="1900-01-01"
      value={selectedDay}
      onChange={(e) => {
        setSelectedDay(e.target.value)
        form.setValue('date_of_birth', dayjs(e.target.value).format('YYYY-MM-DDTHH:mm:ss.000[Z]'))
      }}
      style={{
        border: '1px solid #dbe0e5',
        backgroundColor: '#ffffff',
        fontFamily: 'inherit',
        height: '40px',
        textIndent: '0.5em',
        color: '#1c3244f2',
        resize: 'none',
        padding: '0',
        fontSize: '12px',
        borderRightColor: '#dbe0e5',
        outline: 'none',
        width: '100%',
      }}
      className="exSHyF" // a styling class
    />
  )

  return (
    <Box as="form" onSubmit={form.handleSubmit(updateForm)}>
      <Text textTransform="uppercase" fontWeight="bold">
        Individual BIO-DATA
      </Text>
      <Divider />
      <DisableTree forRoles={[roles.POS_TEAM, roles.SERVICE_OWNER]}>
        <TextField disabled={true} label="Fullname" name="full_name" defaultValue={agent?.full_name} />
        <Spacer mt="lg" />
        <TextField
          label="First Name"
          name="first_name"
          error={form.errors?.first_name}
          helperText={form.errors?.first_name && 'Please enter agent first name'}
          ref={form.register({ required: true, minLength: 3 })}
          defaultValue={agent?.first_name}
        />
        <Spacer mt="lg" />
        <TextField
          label="Surname (Last Name)"
          name="last_name"
          error={form.errors?.last_name}
          helperText={form.errors?.last_name && 'Please enter agent last name'}
          ref={form.register({ required: true, minLength: 3 })}
          defaultValue={agent?.last_name}
        />
        <Spacer mt="lg" />
        <TextField
          label="Middle Name"
          name="middlename"
          error={form.errors?.middlename}
          helperText={form.errors?.middlename && 'Please enter agent middle name'}
          ref={form.register({ required: false, minLength: 3 })}
          defaultValue={agent?.middle_name}
        />
        <Spacer mt="lg" />
        <TextField
          label="Username"
          name="username"
          error={form.errors?.username}
          helperText={form.errors?.username && 'Please enter a username'}
          ref={form.register({ required: true, minLength: 3 })}
          defaultValue={agent?.username}
        />

        <Spacer mt="lg" />

        <TextField
          label="Gender"
          name="gender"
          error={form.errors?.gender}
          helperText={form.errors?.gender && 'Please enter a gender'}
          ref={form.register({ required: false, minLength: 3 })}
          defaultValue={agent?.gender}
        />

        <Spacer mt="lg" />
        <Box display="flex" flexDirection="column">
          <Label>Date of Birth</Label>
          <Spacer mt="xs" />
          {renderCustomInput()}
        </Box>

        <Spacer mt="lg" />
        <Box display="flex" gridGap="1em" alignItems="center">
          <Text>KYC Image capture</Text>
          <Box
            width="61px"
            height="23px"
            padding="4px 8px"
            borderRadius="12px"
            backgroundColor={agent.passport_verify ? '#52BE95' : '#D8314F'}
            color="white"
            fontSize="12px"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            {agent.passport_verify ? 'verified' : 'unverified'}
          </Box>
        </Box>
        <Spacer mt="sm" />
        <Box
          display="flex"
          alignItems="center"
          border="1px solid #CADCE0"
          height="50px"
          justifyContent="flex-end"
          px="6px"
          py="10px"
        >
          <Box
            background="#2CA0C8"
            color="white"
            width="98px"
            height="32px"
            display="flex"
            gridGap="0.05em"
            justifyContent="center"
            alignItems="center"
          >
            <a download="Passport.jpg" target="_blank" rel="noreferrer" href={agent.passport}>
              <Text pr="10px">Download</Text>
            </a>
            <DownloadIcon />
          </Box>
        </Box>
      </DisableTree>
      <Spacer mt="xl" />
      <Text fontWeight="bold">ONBOARDING DATE</Text>
      <TextField py="6px" disabled={true} defaultValue={formatDateTime(agent.regdate)} />
      <Spacer mt="xl" />
      <Text textTransform="uppercase" fontWeight="bold">
        Individual Contact details
      </Text>
      <Divider />
      <TextField
        name="phone"
        error={form.errors?.phone}
        helperText={form.errors?.phone && 'Please enter a phone number'}
        ref={form.register({ required: false, minLength: 11, maxLength: 20 })}
        label="Phone Number"
        type="tel"
        inputProps={{
          minLength: 11,
          maxLength: 20,
        }}
        defaultValue={agent?.phone}
      />
      <Spacer mt="lg" />
      <DisableTree forRoles={[roles.POS_TEAM, roles.SERVICE_OWNER]}>
        <TextField
          name="email"
          error={form.errors?.email}
          type="email"
          helperText={form.errors?.email && 'Please enter a valid email'}
          ref={form.register({ required: false, minLength: 3 })}
          label="Email Address"
          defaultValue={agent?.email}
        />
        <Spacer mt="lg" />
        <TextField
          name="state"
          error={form.errors?.state}
          helperText={form.errors?.state && 'Please enter a state'}
          ref={form.register({ required: false, minLength: 3 })}
          label="State"
          defaultValue={agent?.state}
        />

        <Spacer mt="lg" />
        <TextField
          name="lga"
          error={form.errors?.lga}
          helperText={form.errors?.lga && 'Please enter a LGA'}
          ref={form.register({ required: false, minLength: 3 })}
          label="Local Government Area (LGA)"
          defaultValue={agent?.lga}
        />

        <Spacer mt="lg" />
        <TextField
          name="address"
          error={form.errors?.address}
          helperText={form.errors?.address && 'Please enter a address'}
          ref={form.register({ required: false, minLength: 3 })}
          label="Street Name"
          defaultValue={agent?.address}
        />

        <Spacer mt="lg" />
      </DisableTree>

      <ShowTree forRoles={[roles.OPPERATION_HEAD, roles.OPERATIONS_ASSISTANT]}>
        <Button my="lg" loading={loading(status)} type="submit" variant="success" fullWidth>
          Save
        </Button>
      </ShowTree>
    </Box>
  )
}

export default BasicDetailsForm
