import * as React from 'react'
import styled from 'styled-components'

/**
 *
 * @returns
 *
 * @function ToggleButton
 *
 * ? Local & Shared Import
 */
import { getRandomId } from './TextField'

const Input = styled.input`
  opacity: 0;
  width: 0;
  height: 0;

  &:checked + span {
    background-color: #1c3244;
  }

  &:focus + span {
    box-shadow: 0 0 1px #2196f3;
  }

  &:checked + span:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
`

const Switch = styled.label`
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
`

const Slider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px;

  &:before {
    position: absolute;
    content: '';
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 50%;
  }
`

const ToggleButton = React.forwardRef(
  ({ checked, onChange, id = getRandomId(), inputProps = {}, ...styleProps }, ref) => {
    return (
      <Switch>
        <Input
          readOnly
          name="name"
          type="checkbox"
          onChange={onChange}
          checked={checked}
          id={id}
          ref={ref}
          {...inputProps}
        />
        <Slider {...styleProps} />
      </Switch>
    )
  }
)

export default ToggleButton
