import React from 'react'
import Box from 'design/elements/Box'
import Panel from 'design/elements/Panel'
import Button from 'design/elements/Button'
import ComboBox from 'design/elements/ComboBox'
import { useQuery } from 'react-query'
import { useToast } from 'toast/ToastContext'
import { fetchKycUsers, assignReviewer } from 'kyc/Kyc.api.client'
export default function AssignReviewerModal(agentId) {
  const { notify, alert, clearAlert } = useToast()
  const [isLoading, setIsLoading] = React.useState(false)
  const [id, setId] = React.useState('')
  const [userName, setUserName] = React.useState('')
  const { data: _users = [], isFetching } = useQuery(`fetch-kyc-users`, fetchKycUsers)
  const users = React.useMemo(() => {
    return _users?.map((item) => ({
      ...item,
      label: item.user_name,
      value: item.id,
    }))
  }, [_users])
  const assignKYCReviewer = React.useCallback(() => {
    clearAlert()
    setIsLoading(true)
    const payload = { agent_id: agentId.agentId, reviewer_id: id, reviewer_username: userName }
    assignReviewer(payload)
      .then(() => {
        setIsLoading(false)
        notify('Assigned successfully')
      })
      .catch((err) => {
        setIsLoading(false)
        alert(err)
      })
  }, [notify, alert, clearAlert, id, userName, agentId])
  return (
    <Box>
      <Panel px="lg" pt="2.5rem" pb="4rem">
        <ComboBox
          label="Reviewer"
          name="reviewer"
          py="xs"
          placeholder="Choose a reviewer"
          options={users}
          loading={isFetching}
          onChange={(item) => {
            setId(item.id)
            setUserName(item.user_name)
          }}
        />
        <Box display="flex" justifyContent="center" alignItems="center">
          <Button variant="blue" type="button" onClick={assignKYCReviewer} loading={isLoading}>
            Assign Reviewer
          </Button>
        </Box>
      </Panel>
    </Box>
  )
}
