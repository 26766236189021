import React from 'react'
import FullTable from 'design/elements/FullTable'
import TR from 'design/elements/TR'
import TD from 'design/elements/TD'
import Elippsis from 'design/icons/Elippsis'
import MenuItem from 'design/elements/MenuItem'
import TH from 'design/elements/TH'
import Box from 'design/elements/Box'
import Text from 'design/elements/Text'
import { formatDateTime } from 'lib/formatters'
import { statusColors } from 'lib/TableStatusColorIndicators'

import { Link } from 'react-router-dom'
import Menu from 'design/elements/Menu'

export default function AgentTable({ paginatedResponse, loading, onPageChange, showModal, setAgentId }) {
  return (
    <FullTable
      elements={paginatedResponse?.data}
      RenderHeaders={RenderHeaders}
      RenderRow={(props) => RenderRow({ ...props })}
      pagination={{
        perPage: paginatedResponse?.page_size,
        total: paginatedResponse?.count,
        currentPage: paginatedResponse?.page_index,
      }}
      loading={loading}
      RenderMenu={(...props) => RenderMenu(...props, showModal, setAgentId)}
      RenderNoData={<h3>There are no agents matching your parameters</h3>}
      onPageChange={onPageChange}
    />
  )
}

const RenderRow = ({ row: agent, triggerMenu }) => {
  const kycLevel = {
    LevelZero: 'KYC level 0',
    LevelOne: 'KYC level 1',
    LevelTwo: 'KYC level 2',
    LevelThree: 'KYC level 3',
    LevelFour: 'KYC level 4',
  }
  return (
    <TR>
      <TD>
        <Text fontWeight={500} textAlign="center">
          {formatDateTime(agent.modified_at)}
        </Text>
      </TD>
      <TD>
        <Box width="150px">
          <Text color="highlight" fontWeight={500}>
            {agent.full_name || 'Name not provided'}
          </Text>
          <Text mt={2}>{agent.username}</Text>
        </Box>
      </TD>
      <TD>
        <Box>
          <Text fontWeight={500}>{agent.phone}</Text>
          <Text mt={2}>{agent.email}</Text>
        </Box>
      </TD>

      <TD>
        <Text fontWeight={500} color={statusColors[agent.passport_status]} textAlign="center">
          {agent.passport_status}
        </Text>
      </TD>
      <TD>
        <Text fontWeight={500} color={statusColors[agent.identity_status]} textAlign="center">
          {agent.identity_status}
        </Text>
      </TD>
      <TD>
        <Text fontWeight={500} color={statusColors[agent.address_status]} textAlign="center">
          {agent.address_status}
        </Text>
      </TD>
      <TD>
        <Text fontWeight={500} textAlign="center">
          {kycLevel[agent.kyc_level]}
        </Text>
      </TD>
      <TD>
        <Text fontWeight={500} textAlign="center">
          {agent.account_type}
        </Text>
      </TD>
      <TD>
        <Text fontWeight={500} color={statusColors[agent.review_status]} textAlign="center">
          {agent.review_status}
        </Text>
      </TD>
      <TD>
        <Text fontWeight={500} textAlign="center">
          {agent.agent_type}
        </Text>
      </TD>
      <TD>
        <Text fontWeight={500} textAlign="center">
          {agent.reviewer}
        </Text>
      </TD>
      <TD textAlign="center" borderLeft="1px solid #e3ecf4">
        <button onClick={triggerMenu}>
          <Elippsis />
        </button>
      </TD>
    </TR>
  )
}

const RenderMenu = (context, closeMenu, popperProps, showModal, setAgentId) => {
  if (!context) {
    return null
  }
  return (
    <Menu {...popperProps()} id="menu1" role="menu" tabIndex={0} aria-labelledby="menubutton">
      <Link to={`/kyc/${context.agent_id}/edit`}>
        <MenuItem role="menuitem" tabIndex="-1">
          Update Details
        </MenuItem>
      </Link>
      <MenuItem
        onClick={() => {
          setAgentId(context?.agent_id)
          closeMenu()
          showModal()
        }}
      >
        Assign Reviewer
      </MenuItem>
      <Link
        to={{
          pathname: `/kyc/${context.agent_id}/documentlist`,
          state: { context },
        }}
      >
        <MenuItem role="menuitem" tabIndex="-1">
          View Kyc Documents
        </MenuItem>
      </Link>
    </Menu>
  )
}

const RenderHeaders = () => (
  <tr>
    <TH textAlign="center">Modified Date</TH>
    <TH>Agent Details</TH>
    <TH>Contact</TH>
    <TH textAlign="center">Passport</TH>
    <TH textAlign="center"> Identity Card</TH>
    <TH textAlign="center">Address Verified</TH>
    <TH textAlign="center">KYC Level</TH>
    <TH textAlign="center">Account type</TH>
    <TH textAlign="center">Review Status</TH>
    <TH textAlign="center">Type</TH>
    <TH textAlign="center">Current Reviewer</TH>
    <TH textAlign="center" />
  </tr>
)
