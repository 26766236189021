import React from 'react'
import Modal from 'design/elements/Modal'
import Box from 'design/elements/Box'
import Panel from 'design/elements/Panel'
import Close from 'design/assets/images/close.svg'
import {useToggle} from 'lib/hooks/useToggle'
import IconBtn from 'design/elements/IconBtn'
import Text from 'design/elements/Text'
import Avatar from 'design/elements/Avatar'

export default function ImageViewer({ src, loading, ...props }) {
  const [showDialog, toggleDialog] = useToggle(false)

  return (
    <>
      <Avatar
        contain
        src={src}
        loading={loading}
        onClick={() => toggleDialog(true)}
        children={<Text> No file uploaded</Text>}
        {...props}
      />
      {showDialog && src && <FileDialog close={() => toggleDialog(false)} src={src} />}
    </>
  )
}
function FileDialog({ close, src }) {
  return (
    <Modal close={close}>
      <Box width="80vw" fontSize="sm">
        <Panel px="xl" py="md" display="flex" justifyContent="space-between" alignItems="center">
          <Text color="highlight" fontWeight="bold">
            IMAGE PREVIEW
          </Text>

          <IconBtn onClick={close} color="#8b9ba8">
            <img src={Close} alt="" />
          </IconBtn>
        </Panel>
        <Panel p={0} display="flex" justifyContent="center" alignItems="center" minHeight="70vh">
          <Box as="img" maxWidth="100%" objectFit="contain" objectPosition="center" src={src} />
        </Panel>
      </Box>
    </Modal>
  )
}
