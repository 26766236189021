import { axios } from 'lib/axios.client'
import client from 'lib/client'

export const getAllApiAgents = (query) => client.get('i/agents/GetApiAgents', query)

// remove axios and use client, axios doesn't know where to get this data from, that's why its not working

export const getAllApiAgentsByUsername = () => axios.get('i/agents/GetMapApiAgents')
export const getSingleApiAgent = (id) => axios.get(`i/agents/ApiAgents/${id}`)
export const updateApp = (body) => axios.patch(`i/account/app/`, body)
export const getAllApps = (query) => client.get('i/account/GetApps', query)
export const getSingleApp = (id) => axios.get(`i/account/GetApps?AppId=${id}`)
export const mapApiToAgentByAgentId = (body) => axios.post(`i/agents/agents/MapApiAgent`, body)
export const mapAppToAgent = (body) => axios.post(`i/agents/MapApiAgent`, body)
export const createNewApp = (body) => axios.post(`i/account/RegisterApp`, body)
