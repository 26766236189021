import React from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import qs from 'lib/queryString'

export default function useSyncHistoryWith(subject, strategy = 'push') {
  const history = useHistory()
  const { url } = useRouteMatch()
  React.useEffect(() => {
    history[strategy]([url, qs(subject)].join(''))
  }, [subject, url, history, strategy])
}
