import React from 'react'
import Button from 'design/elements/Button'
import TextField from 'design/elements/TextField'
import Text from 'design/elements/Text'
import Panel from 'design/elements/Panel'
import Spacer from 'design/elements/Spacer'
import { useForm } from 'react-hook-form'
import { login } from 'auth/api.client.js'
import { TOKEN_KEY, USER_KEY, TOKEN_EXPIRY_THRESHOLD, TOKEN_EXPIRY_KEY } from 'auth/context/Auth.Context'
import { useMutation } from 'react-query'
import { loading } from 'lib/formatters'
import AuthShell from './AuthTemplate'
import { Link } from 'react-router-dom'
import { User } from '../lib/models'
import Box from 'design/elements/Box'
import useCoordsAndIP from './LocationInfo'

export default function Login({ onLogin }) {
  const { errors, handleSubmit, register, watch } = useForm({ mode: 'onBlur' })
  const username = watch('username')
  const password = watch('password')

  const [mutate, { error, status }] = useMutation(login, {
    onSuccess: afterLogin,
  })

  function afterLogin({ token, ...user }) {
    if (User.isAStaff(user)) {
      sessionStorage.setItem(TOKEN_KEY, JSON.stringify(token))
      sessionStorage.setItem(USER_KEY, JSON.stringify(user))
      window.loadOpenReplayWithUserID(user.agent_username)
      sessionStorage.setItem(TOKEN_EXPIRY_KEY, TOKEN_EXPIRY_THRESHOLD + Date.now())
      onLogin(user, token)
    } else {
      const params = `from=payforce&cxdn=${btoa(JSON.stringify({ token, ...user }))}`
      const url = `https://gateforce-agents.azurewebsites.net?${params}`
      window.location.replace(url)
    }
  }

  const { userIP, userLocation } = useCoordsAndIP()
  function onSubmit(values) {
    const data = {
      username: values.username,
      password: values.password,
      channel: 'gateforce_web',
      device_imei: values.username,
      Ipaddress: userIP.ip,
      latitude: userLocation.latitude,
      longitude: userLocation.longitude,
    }
    mutate(data)
  }

  return (
    <AuthShell>
      <Panel as="form" method="post" onSubmit={handleSubmit(onSubmit)} p={64} curved="4px">
        <Box textAlign="center" borderBottom="8px #F9F8FB solid" borderRadius="2px" px="30px">
          <Text fontSize="24px" as="h1" color="black" fontWeight="bold" mb="sm">
            Welcome to GateForce Portal
          </Text>
          <Text fontSize="16px" as="p" color="#667085" fontWeight="normal" lineHeight="20px" mb="xl">
            Please input your log in credentials to gain access to the dashboard
          </Text>
        </Box>

        <Spacer mt="xl" />

        <TextField
          error={Boolean(errors.username)}
          name="username"
          helperText={errors.username?.message}
          ref={register({
            required: true,
            pattern: {
              value: /[\w]{3,}/,
              message: 'Username must be 3 characters or more',
            },
          })}
          required
          placeholder="Username"
          label="Username"
          borderRadius="4px"
        />
        <Spacer mt="md" />

        <TextField
          error={Boolean(errors.password)}
          name="password"
          helperText={Boolean(errors?.password?.message && 'Please provide a password')}
          ref={register({ required: true })}
          type="password"
          required
          placeholder="Password"
          label="Password"
          showPassword={true}
          borderRadius="4px"
        />

        <Spacer mt="md" />
        {error && (
          <>
            <Text color="red" fontSize="x-small" textAlign="center">
              {error}
            </Text>
            <Spacer mt="xs" />
          </>
        )}

        <Button
          loading={loading(status)}
          fullWidth
          variant="dark"
          borderRadius="4px"
          disabled={!username || !password}
        >
          Login
        </Button>
        <Spacer mt="md" />

        <Text mt="xl" color="#667085" fontWeight="normal" textAlign="center" fontSize="14px">
          Forgot Password?
          <Text
            as="span"
            color="highlight"
            fontWeight="500"
            textAlign="center"
            fontSize="16px"
            borderBottom="1px solid #1C3244"
            ml="6px"
          >
            <Link to="/forgot-password">Click here</Link>
          </Text>
        </Text>
      </Panel>
    </AuthShell>
  )
}
