import React from 'react'
/**
 * ? Local Imports
 */
import { loading } from 'lib/formatters'
import Box from 'design/elements/Box'
import Button from 'design/elements/Button'
import Divider from 'design/elements/Divider'
import Text from 'design/elements/Text'
import TextField from 'design/elements/TextField'
import { AdditionalContactDetailsOption } from 'agents/config'
import Select from 'design/elements/Select'
import { useContactDetailsForm } from 'agents/hooks/useContactDetailsForm'

const AdditionalContactDetailsForm = ({ agent, notify, alert }) => {
  const [register, errors, status, handleSubmit, data, setData] = useContactDetailsForm({ agent, notify, alert })

  return (
    <Box as="form" onSubmit={handleSubmit}>
      <Text textTransform="uppercase" fontWeight="bold">
        ADDITIONAL CONTACT DETAILS
      </Text>
      <Divider />
      <Select
        name="agent_contact_type"
        placeholder="Select an Option"
        options={AdditionalContactDetailsOption}
        labelField="name"
        valueField="value"
        label="Agent contact type"
        value={data?.agent_contact_type}
        onChange={(option) => setData((d) => ({ ...d, agent_contact_type: option.value }))}
        required
        py="sm"
      />
      {data.agent_contact_type === 0 && (
        <TextField
          name="agent_contact_info"
          error={errors?.phone}
          helperText={errors?.phone && 'Please enter a phone number'}
          ref={register({ required: false, minLength: 11, maxLength: 20 })}
          label="Phone Number"
          type="tel"
          inputProps={{
            minLength: 11,
            maxLength: 20,
          }}
          defaultValue={data?.phone}
          onChange={(e) => setData((d) => ({ ...d, agent_contact_info: e.target.value }))}
        />
      )}
      {data.agent_contact_type === 1 && (
        <TextField
          name="agent_contact_info"
          error={errors?.email}
          type="email"
          helperText={errors?.email && 'Please enter a valid email'}
          ref={register({ required: false, minLength: 3 })}
          label="Email Address"
          defaultValue={data?.email}
          onChange={(e) => setData((d) => ({ ...d, agent_contact_info: e.target.value }))}
        />
      )}
      {data.agent_contact_type === 2 && (
        <TextField
          name="agent_contact_info"
          error={errors?.address}
          helperText={errors?.address && 'Please enter an address'}
          ref={register({ required: false, minLength: 3 })}
          label="Address"
          defaultValue={data?.address}
          onChange={(e) => setData((d) => ({ ...d, agent_contact_info: e.target.value }))}
        />
      )}

      <Button my="lg" type="submit" variant="success" fullWidth loading={loading(status)}>
        Save
      </Button>
    </Box>
  )
}

export default AdditionalContactDetailsForm
