import React from 'react'
import Box from 'design/elements/Box'
import Text from 'design/elements/Text'
import Divider from 'design/elements/Divider'
import FieldText from 'design/elements/FieldText'
import Spacer from 'design/elements/Spacer'

const KycDataVerification = ({agent}) => {
  return (
    <Box>
      <Text textTransform="uppercase" fontWeight="bold">
        KYC Data verification
      </Text>
      <Divider />
      <Box
        isValue
        background={agent?.address_verify ? '#D0F3E5' : '#F5DCE1'}
        display="flex"
        alignItems="center"
        justifyContent="Space-between"
        height="50px"
        padding="8px"
      >
        <Box>
          <FieldText isLabel color="#0F1C27" fontWeight="bold">
            Address Verification Status
          </FieldText>
          <FieldText isLabel color="#8095A7" fontSize="12px">
            Proof of address information verification
          </FieldText>
        </Box>
        <Box
          isValue
          background={agent?.address_verify ? '#52BE95' : '#D8314F'}
          color="white"
          width="88px"
          height="23px"
          borderRadius="14px"
          padding="10px 6px"
          display="flex"
          alignItems="center"
          justifyContent="center"
          fontSize="12px"
        >
          {agent?.address_verify ? `Verified` : 'Not Verified'}
        </Box>
      </Box>

      <Spacer mt="1em" />
      <Box
        isValue
        background={agent?.passport_verify ? '#D0F3E5' : '#F5DCE1'}
        display="flex"
        alignItems="center"
        justifyContent="Space-between"
        height="50px"
        padding="8px"
      >
        <Box>
          <FieldText isLabel color="#0F1C27" fontWeight="bold">
            Passport Verification Status
          </FieldText>
          <FieldText isLabel color="#8095A7" fontSize="12px">
            Verification of uploaded passport
          </FieldText>
        </Box>
        <Box
          isValue
          background={agent?.passport_verify ? '#52BE95' : '#D8314F'}
          color="white"
          width="88px"
          height="23px"
          borderRadius="14px"
          padding="10px 6px"
          display="flex"
          alignItems="center"
          justifyContent="center"
          fontSize="12px"
        >
          {agent?.passport_verify ? `Verified` : 'Not Verified'}
        </Box>
      </Box>
      <Spacer mt="1em" />
      <Box
        isValue
        background={agent?.is_bvn_verified ? '#D0F3E5' : '#F5DCE1'}
        display="flex"
        alignItems="center"
        justifyContent="Space-between"
        height="50px"
        padding="8px"
      >
        <Box>
          <FieldText isLabel color="#0F1C27" fontWeight="bold">
            BVN Verification Status
          </FieldText>
          <FieldText isLabel color="#8095A7" fontSize="12px">
            BVN verification
          </FieldText>
        </Box>
        <Box
          isValue
          background={agent?.is_bvn_verified ? '#52BE95' : '#D8314F'}
          color="white"
          width="88px"
          height="23px"
          borderRadius="14px"
          padding="10px 6px"
          display="flex"
          alignItems="center"
          justifyContent="center"
          fontSize="12px"
        >
          {agent?.is_bvn_verified ? `Verified` : 'Not Verified'}
        </Box>
      </Box>

      <Spacer mt="1em" />
      <Box
        isValue
        background={agent?.id_verify ? '#D0F3E5' : '#F5DCE1'}
        display="flex"
        alignItems="center"
        justifyContent="Space-between"
        height="50px"
        padding="8px"
      >
        <Box>
          <FieldText isLabel color="#0F1C27" fontWeight="bold">
            Identity Verification Status
          </FieldText>
          <FieldText isLabel color="#8095A7" fontSize="12px">
            Identity verification
          </FieldText>
        </Box>
        <Box
          isValue
          background={agent?.id_verify ? '#52BE95' : '#D8314F'}
          color="white"
          width="88px"
          height="23px"
          borderRadius="14px"
          padding="10px 6px"
          display="flex"
          alignItems="center"
          justifyContent="center"
          fontSize="12px"
        >
          {agent?.id_verify ? `Verified` : 'Not Verified'}
        </Box>
      </Box>
    </Box>
  )
}

export default KycDataVerification