import React from 'react'
import Button from './Button'
import styled from 'styled-components'
import Upload from 'design/assets/images/upload.svg'
import Spacer from './Spacer'

const UploadStyles = styled(Button)`
  input {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }
  label {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`
export default React.forwardRef(function UploadButton(
  { children, onChange, name, fileName, accept, error = false, disabled, ...props },
  ref
) {
  return (
    <UploadStyles type="button" disabled={disabled} variant={error ? 'error' : 'default'} {...props}>
      <input
        key={name || fileName}
        ref={ref}
        onChange={onChange}
        id={fileName}
        name={name || fileName}
        type="file"
        accept={accept || '*'}
      />
      <label htmlFor={fileName}>
        <img src={Upload} alt="" width={15} />
        <Spacer ml="xs" />
        {children}
      </label>
    </UploadStyles>
  )
})
