import React from 'react'
import FullTable from 'design/elements/FullTable'
import TR from 'design/elements/TR'
import TD from 'design/elements/TD'
import TH from 'design/elements/TH'
import Text from 'design/elements/Text'
import { formatDateTime } from 'lib/formatters'
import { statusColors } from 'lib/TableStatusColorIndicators'

export default function KycDocumentHistoryTable({ Documents, loading }) {
  return (
    <FullTable
      elements={Documents}
      RenderHeaders={RenderHeaders}
      RenderRow={(props) => RenderRow({ ...props })}
      loading={loading}
      RenderNoData={<h3>There is no history log for this document</h3>}
    />
  )
}

const RenderRow = ({ row: agent }) => {
  let currentStatus = 'Uploaded'
  if (agent.rejected_at !== null) {
    currentStatus = 'Rejected'
  } else if (agent.approved_at !== null) {
    currentStatus = 'Approved'
  }

  return (
    <TR>
      <TD>
        <Text fontWeight={500} textAlign="center">
          {formatDateTime(agent.performed_at)}
        </Text>
      </TD>
      <TD>
        <Text fontWeight={500} textAlign="center">
          {agent.performed_by}
        </Text>
      </TD>
      <TD>
        <Text fontWeight={500} color={statusColors[currentStatus]} textAlign="center">
          {currentStatus}
        </Text>
      </TD>
    </TR>
  )
}

const RenderHeaders = () => (
  <tr>
    <TH textAlign="center">APPROVE/REJECT DATE</TH>
    <TH textAlign="center"> MODIFIED BY</TH>
    <TH textAlign="center"> ACTION</TH>
  </tr>
)
