import React from 'react'
import Box from './Box'
import styled from 'styled-components'
import { layout } from 'styled-system'
import { InputLoader } from 'design/loaders'

const AvatarBox = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${(props) => props.fontSize || '24px'};
  color: inherit;
  background: ${(props) => (props.src ? `url(${props.src})` : props.theme.colors.lightgrey)};
  background-size: ${(props) => (props.contain ? 'contain' : 'cover')};
  background-repeat: no-repeat;
  background-position: center;
  width: ${(props) => (props.width ? `${props.width}px` : '250px')};
  height: ${(props) => (props.height ? `${props.height}px` : '200px')};
  border-radius: ${(props) => (props.rounded ? '50%' : '0')};
  ${layout}
`
function Avatar({ src, loading, alt, children, ...props }, ref) {
  return (
    <AvatarBox {...props} src={src}>
      {loading ? <InputLoader /> : src ? null : children}
    </AvatarBox>
  )
}
export default React.forwardRef(Avatar)
