import React from 'react'
import { useQuery, queryCache } from 'react-query'
import { fetchAgentDetails } from 'agents/agents.api.client'
import { useParams } from 'react-router-dom'
import { loading } from 'lib/formatters'
import KycEditAgent from './KycEditAgent'
import withTitle from 'lib/withTitle'

export default withTitle('Agents', KycEditAgentWrapper)

function KycEditAgentWrapper({ notify, alert }) {
  const { agent_id } = useParams()

  const cachedDetails = queryCache
    .getQueries('agents')
    ?.map((q) => q.state.data.data)
    .flat()
    .find((a) => a.agent_id == agent_id)

  const { data: agentDetails = cachedDetails, status } = useQuery(
    ['agent_details', agent_id],
    (_, id) => fetchAgentDetails(id),
    {
      staleTime: 5 * 60 * 1000,
      notifyOnStatusChange: false,
    }
  )

  return <KycEditAgent notify={notify} alert={alert} agent={agentDetails} loading={loading(status)} />
}
