import client from 'lib/client'
import { X_REQUEST_LOCK } from 'lib/config'

export const fetchAgentKycStatusReport = (query) =>
  client.get('i/agents/AgentKycStatusReport', query, { headers: { 'X-REQUEST-LOCK': X_REQUEST_LOCK } })

export const fetchAgentKycLevels = () => client.get('i/agents/GetAgentKycLevel')

export const fetchKycUsers = () => client.get(`i/account/GetUsersInKycTeamRole`)
export const assignReviewer = (params) => client.post(`i/agents/AssignAgentKycReviewer`, params)
export const fetchReviewerStatusList = () => client.get(`i/agents/GetKycReviwerStatusList`)
export const fetchOrderList = () => client.get(`i/agents/GetOrderByDateList`)
export const fetchAgentKycDocuments = (agentId) => client.get(`i/agents/getAgentDocuments/${agentId}`)
export const fetchAgentKycDocumentHistory = (agentId, agentDocumentId) =>
  client.get('i/agents/KycActivityLogs', { agentId, agentDocumentId })
